import React, { useEffect, useState } from 'react';
import BreadcrumbComponent from '../../../components/BreadcrumbComponent';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Tabs } from 'antd';
import usePermissions from '../../../hooks/usePermissions';
import { PermissionName } from '../../../constants/permissions';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../redux/store';
import { fetchProjectById } from '../../../redux/projects/projectsSlice';
import UserSvg from '../../../assets/svg/user';
import PermissionAssignComponent from './PermissionAssignComponent';
import LoadingComponent from '../../../components/LoadingComponent';
import { fetchCompanyById } from '../../../redux/companies/companiesSlice';
import ProjectLaunchComponent from '../ProjectLaunchComponent';
import ProjectBoostComponent from '../ProjectBoost/ProjectBoostComponent';
import ProjectTakeProfitsComponent from '../ProjectTakeProfitsComponent';
import ChartGeneratorListComponent from '../../ChartGenerator2/ChartGeneratorListComponent';
import CustomSegmentedComponent from '../../../components/CustomSegmentedComponent';
import DataStorageSvg from '../../../assets/svg/dataStorage';
import BotManageSvg from '../../../assets/svg/botManage';
import ManualUpdateSvg from '../../../assets/svg/botManage copy';
import ProjectSyncswapMonitorComponent from './ProjectMonitorComponent';

const ProjectSyncswapOverviewComponent = () => {
   const params = useParams();
   const navigate = useNavigate();
   const { hasPermission, isInternalUser } = usePermissions();

   const { project, loading: projectLoading } = useSelector(
      (state: RootState) => state.projects,
   );

   const { company, loading: companyLoading } = useSelector(
      (state: RootState) => state.companies,
   );

   const dispatch = useAppDispatch();

   const [isModalOpenAccess, setIsModalOpenAccess] = useState<boolean>(false);
   const [activeKeyMMGenerator, setActiveKeyMMGenerator] = useState('1');

   useEffect(() => {
      if (!project && !projectLoading && params?.id) {
         dispatch(fetchProjectById(+params?.id, navigate));
      }
   }, [dispatch, project, projectLoading]);

   useEffect(() => {
      if (params?.companyId) {
         dispatch(fetchCompanyById(+params?.companyId, navigate));
      }
   }, [dispatch, params?.companyId]);

   const segmentedItemsMMGenerator = [
      {
         key: '1',
         text: 'Data Storage',
         icon: DataStorageSvg,
         hasPermission:
            hasPermission(PermissionName.ACCESS_SYNCSWAP) &&
            hasPermission(PermissionName.MMLIST_VIEW),
         component: <ChartGeneratorListComponent />,
      },
      {
         key: '2',
         text: 'Bot Manage',
         icon: BotManageSvg,
         disabled: true,
      },
      {
         key: '3',
         text: 'Manual Update',
         icon: ManualUpdateSvg,
         disabled: true,
      },
   ];

   const projectMonitorBreadcrumbs = isInternalUser
      ? [
           {
              title: 'Project Management',
              link: `/company/projects`,
           },
           {
              title: (
                 <LoadingComponent size={20} loading={companyLoading}>
                    {company?.name}
                 </LoadingComponent>
              ),
              link: `/company/${params?.companyId}/project/`,
           },
           {
              title: (
                 <LoadingComponent size={20} loading={projectLoading}>
                    {project?.name}
                 </LoadingComponent>
              ),
           },
        ]
      : [
           {
              title: 'Project Management',
              link: `/company/${params?.companyId}/project`,
           },
           {
              title: (
                 <LoadingComponent size={20} loading={projectLoading}>
                    {project?.name}
                 </LoadingComponent>
              ),
              link: `/project-syncswap/${params?.id}/monitor`,
           },
        ];
   return (
      <div className="projects-overview-component">
         <div className="flex justify-between items-center">
            <BreadcrumbComponent items={projectMonitorBreadcrumbs} />

            <div className="flex gap-3">
               {hasPermission(PermissionName.PROJECT_ASSIGN) && (
                  <>
                     <Button
                        type="primary"
                        icon={<UserSvg />}
                        onClick={() => setIsModalOpenAccess(true)}
                     >
                        Assign
                     </Button>
                     <PermissionAssignComponent
                        id={params?.id}
                        isModalOpen={isModalOpenAccess}
                        setIsModalOpen={setIsModalOpenAccess}
                     />
                  </>
               )}
            </div>
         </div>

         <Tabs defaultActiveKey="1" destroyInactiveTabPane>
            {hasPermission(PermissionName.ACCESS_SYNCSWAP) &&
               hasPermission(PermissionName.MONITOR_OVERVIEW) && (
                  <Tabs.TabPane tab="Monitor" key="1">
                     <ProjectSyncswapMonitorComponent
                        project={project}
                        projectLoading={projectLoading}
                     />
                  </Tabs.TabPane>
               )}
            {hasPermission(PermissionName.ACCESS_SYNCSWAP) &&
               hasPermission(PermissionName.MMLIST_VIEW) && (
                  <Tabs.TabPane tab="MM Generator" key="2">
                     <CustomSegmentedComponent
                        items={segmentedItemsMMGenerator}
                        activeKey={activeKeyMMGenerator}
                        setActiveKey={setActiveKeyMMGenerator}
                        defaultActiveKey="1"
                     />
                  </Tabs.TabPane>
               )}
            {hasPermission(PermissionName.ACCESS_SYNCSWAP) &&
               hasPermission(PermissionName.BOOST_VIEW) && (
                  <Tabs.TabPane tab="Boost" key="3">
                     <ProjectBoostComponent />
                  </Tabs.TabPane>
               )}

            {hasPermission(PermissionName.ACCESS_SYNCSWAP) &&
               hasPermission(PermissionName.LAUNCH_VIEW) && (
                  <Tabs.TabPane tab="Launch" key="4">
                     <ProjectLaunchComponent />
                  </Tabs.TabPane>
               )}
            {hasPermission(PermissionName.ACCESS_SYNCSWAP) &&
               hasPermission(PermissionName.TAKEPROFIT_VIEW) && (
                  <Tabs.TabPane tab="Take Profits" key="5">
                     <ProjectTakeProfitsComponent />
                  </Tabs.TabPane>
               )}
         </Tabs>
      </div>
   );
};

export default ProjectSyncswapOverviewComponent;
