import React from 'react';

export interface SegmentedItem {
   key: string;
   text: string;
   icon: React.FC<{ fill: string }>;
   hasPermission?: boolean;
   component?: React.ReactNode;
   disabled?: boolean;
}

interface CustomSegmentedComponentProps {
   items: SegmentedItem[];
   activeKey: string;
   setActiveKey: (key: string) => void;
   defaultActiveKey?: string;
}

const CustomSegmentedComponent: React.FC<CustomSegmentedComponentProps> = ({
   items,
   activeKey,
   setActiveKey,
}) => {
   const defaultFill = '#787878';
   const activeFill = '#0ca7ee';

   const itemsWithDefaultPermission = items.map((item) => ({
      ...item,
      hasPermission: item.hasPermission ?? true,
   }));
   const filteredItems = itemsWithDefaultPermission.filter(
      (item) => item.hasPermission,
   );
   const activeItem = filteredItems.find((item) => item.key === activeKey);

   const handleItemClick = (item: SegmentedItem) => {
      if (!item.disabled) {
         setActiveKey(item.key);
      }
   };

   return (
      <div className="flex flex-col gap-4">
         <div className="segmented-component mt-3 pb-2 flex gap-[16px] justify-between">
            {filteredItems.map((item) => (
               <div
                  key={item.key}
                  className={`segmented-item flex items-center gap-2 ${
                     activeKey === item.key ? 'segmented-item-active' : ''
                  } ${
                     item.disabled
                        ? '!bg-[#EEEEEE] !cursor-not-allowed'
                        : '!cursor-pointer'
                  }`}
                  onClick={() => handleItemClick(item)}
               >
                  {item.icon && (
                     <item.icon
                        fill={activeKey === item.key ? activeFill : defaultFill}
                     />
                  )}
                  <span className="text">{item.text}</span>
               </div>
            ))}
         </div>
         {activeItem?.component && (
            <div className="tab-content">{activeItem.component}</div>
         )}
      </div>
   );
};

export default CustomSegmentedComponent;
