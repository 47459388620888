import React from 'react';

const TopbuyerSvg: React.FC<{ fill: string }> = ({ fill }) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
   >
      <path
         d="M29 8.25H25.75V6C25.75 5.80109 25.671 5.61032 25.5303 5.46967C25.3897 5.32902 25.1989 5.25 25 5.25H7C6.80109 5.25 6.61032 5.32902 6.46967 5.46967C6.32902 5.61032 6.25 5.80109 6.25 6V8.25H3C2.53587 8.25 2.09075 8.43437 1.76256 8.76256C1.43437 9.09075 1.25 9.53587 1.25 10V12C1.25 13.2598 1.75044 14.468 2.64124 15.3588C3.53204 16.2496 4.74022 16.75 6 16.75H6.6425C7.20096 18.6476 8.32339 20.3304 9.86076 21.5751C11.3981 22.8198 13.2777 23.5674 15.25 23.7188V27.25H12C11.8011 27.25 11.6103 27.329 11.4697 27.4697C11.329 27.6103 11.25 27.8011 11.25 28C11.25 28.1989 11.329 28.3897 11.4697 28.5303C11.6103 28.671 11.8011 28.75 12 28.75H20C20.1989 28.75 20.3897 28.671 20.5303 28.5303C20.671 28.3897 20.75 28.1989 20.75 28C20.75 27.8011 20.671 27.6103 20.5303 27.4697C20.3897 27.329 20.1989 27.25 20 27.25H16.75V23.7188C20.805 23.4037 24.1787 20.5562 25.3275 16.75H26C27.2598 16.75 28.468 16.2496 29.3588 15.3588C30.2496 14.468 30.75 13.2598 30.75 12V10C30.75 9.53587 30.5656 9.09075 30.2374 8.76256C29.9092 8.43437 29.4641 8.25 29 8.25ZM6 15.25C5.13805 15.25 4.3114 14.9076 3.7019 14.2981C3.09241 13.6886 2.75 12.862 2.75 12V10C2.75 9.9337 2.77634 9.87011 2.82322 9.82322C2.87011 9.77634 2.9337 9.75 3 9.75H6.25V14C6.25122 14.4179 6.27835 14.8354 6.33125 15.25H6ZM24.25 13.8875C24.25 18.465 20.5775 22.2162 16.0625 22.25C14.9738 22.2582 13.8943 22.0509 12.8862 21.64C11.878 21.2291 10.9611 20.6227 10.1884 19.8558C9.41571 19.0889 8.80242 18.1767 8.38392 17.1716C7.96541 16.1666 7.74997 15.0887 7.75 14V6.75H24.25V13.8875ZM29.25 12C29.25 12.862 28.9076 13.6886 28.2981 14.2981C27.6886 14.9076 26.862 15.25 26 15.25H25.6537C25.7175 14.7986 25.7496 14.3434 25.75 13.8875V9.75H29C29.0663 9.75 29.1299 9.77634 29.1768 9.82322C29.2237 9.87011 29.25 9.9337 29.25 10V12Z"
         fill={fill}
      />
   </svg>
);

export default TopbuyerSvg;
