import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import { getProjectByID } from '../../services/projectServices';
import { Project } from './type';
import { DEFAULT_MM_PROJECT_ID, DEFAULT_PROJECT } from '../../constants/common';
import BigNumber from 'bignumber.js';

type ProjectState = {
   project: Project | null;
   loading: boolean;
   error: string | null;
};

const initialState: ProjectState = {
   project: null,
   loading: false,
   error: null,
};

const projectsSlice = createSlice({
   name: 'projects',
   initialState,
   reducers: {
      setProject(state, action: PayloadAction<Project | null>) {
         state.project = action.payload;
         state.loading = false;
         state.error = null;
      },
      setLoading(state, action: PayloadAction<boolean>) {
         state.loading = action.payload;
      },
      setError(state, action: PayloadAction<string | null>) {
         state.error = action.payload;
         state.loading = false;
      },
   },
});

export const { setProject, setLoading, setError } = projectsSlice.actions;

export default projectsSlice.reducer;

export const fetchProjectById =
   (id: number, navigate: (path: string) => void): AppThunk =>
   async (dispatch) => {
      dispatch(setProject(null));
      dispatch(setLoading(true));
      try {
         const projID = new BigNumber(id);
         if (projID.eq(DEFAULT_MM_PROJECT_ID)) {
            dispatch(setProject(DEFAULT_PROJECT));
         } else {
            const resProj = await getProjectByID(id);
            const project = resProj?.data?.data as Project;
            dispatch(setProject(project));
         }
      } catch (err) {
         navigate('/company/projects');
         dispatch(setProject(null));
      } finally {
         dispatch(setLoading(false));
      }
   };
