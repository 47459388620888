/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import CaretRight from '../assets/svg/caretRight';

interface BreadcrumbComponentProps {
   items: any;
   className?: string;
}

const BreadcrumbComponent: React.FC<BreadcrumbComponentProps> = ({
   items,
   className = '',
}) => {
   return (
      <div>
         <Breadcrumb
            className={`breadcrumb-block ${className}`}
            separator={
               <div className="h-full flex items-center justify-center">
                  <CaretRight />
               </div>
            }
            items={(items || []).map((item: any) => ({
               title: item?.link ? (
                  <Link to={item?.link}>{item?.title}</Link>
               ) : (
                  item?.title
               ),
            }))}
         />
      </div>
   );
};

export default BreadcrumbComponent;
