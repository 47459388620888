import BigNumber from "bignumber.js";

export function convertNumberToSeparatedString(value: number | BigNumber | string, separator = ','): string {
    const numberString = new BigNumber(value).toFixed();

    const [integerPart, decimalPart] = numberString.split('.');

    const separatedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, separator);

    return decimalPart ? `${separatedInteger}.${decimalPart}` : separatedInteger;
}
