import { Project } from '../redux/projects/type';
import { SupportChain } from './chains';
import { CompanyType } from './company';
import { ProjectSpace, ProjectVersion } from './project';
import { UserType } from './user';

export const ERROR_MESSAGE = 'Something went wrong. Try again later';

export const DEFAULT_PAGINATE_SIZE = 20;

export const TIME_ZONE = 7;

export const DEFAULT_DATETIME_FORMAT = 'HH:mm:ss DD-MM-YYYY';

export const DEFAULT_DATETIME_FORMAT_FILE = 'HH[h]mm DD-MM-YYYY';

export const DEFAULT_PASSWORD = 'Password@123';

export const AQUAFOX_ID = '1';

export const AQUAFOX_NAME = 'AquaFox JSC';

export const DEFAULT_MM_PROJECT_ID = '0';

export const DEFAULT_PROJECT: Project = {
   createdBy: '1',
   updatedBy: '1',
   deletedBy: null,
   createdAt: '2025-01-14T09:35:12.635Z',
   updatedAt: '2025-01-16T05:07:22.137Z',
   deletedAt: null,
   id: DEFAULT_MM_PROJECT_ID,
   name: '',
   tokenAddress: '',
   tokenSymbol: '',
   tokenName: '',
   tokenDecimal: 18,
   tokenTotalSupply: '1.1',
   status: '1',
   space: ProjectSpace.UNISWAP,
   predicthubStrateryId: null,
   chainId: SupportChain.Base,
   creatorId: '1',
   deployerAddress: '',
   operatorAddresses: [],
   tradeAddress: '',
   pairAddress: '',
   ownerAddress: '',
   version: ProjectVersion.VERSION_1,
   projectStat: {
      createdBy: '1',
      updatedBy: '1',
      deletedBy: null,
      createdAt: '2025-01-14T09:35:12.635Z',
      updatedAt: '2025-01-16T05:07:22.137Z',
      deletedAt: null,
      id: '1',
      balanceEth: '0.000000000000000000',
      initialEth: '0.000000000000000000',
      plnEth: '0.000000000000000000',
      otherCosts: [],
      initialZKs: [],
      tokenReceived: [],
   },
   creator: {
      createdBy: null,
      updatedBy: 1,
      deletedBy: null,
      createdAt: '2024-12-08T10:40:10.175Z',
      updatedAt: '2025-01-03T09:48:20.399Z',
      deletedAt: null,
      id: 1,
      username: '1',
      status: '1',
      type: UserType.INTERNAL,
   },
   company: {
      createdBy: '1',
      updatedBy: null,
      deletedBy: null,
      createdAt: '2024-12-30T03:58:24.383Z',
      updatedAt: '2024-12-30T03:58:24.383Z',
      deletedAt: null,
      id: AQUAFOX_ID,
      name: AQUAFOX_NAME,
      type: CompanyType.INTERNAL,
   },
   dbConnection: {
      createdBy: '1',
      updatedBy: '1',
      deletedBy: null,
      createdAt: '2025-01-14T09:35:12.635Z',
      updatedAt: '2025-01-14T09:35:12.635Z',
      deletedAt: null,
      id: '1',
      dbName: '1',
      projectId: DEFAULT_MM_PROJECT_ID,
   },
};
