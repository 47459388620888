/* eslint-disable @typescript-eslint/no-explicit-any */
import axiosInstance from '../axios/axiosInstance';

export const syncProjectFund = () => {
   return axiosInstance.get('/v1/sync/project-fund');
};

export const syncTotalFund = () => {
   return axiosInstance.get('/v1/sync/wallet-balance');
};

export const syncListWallet = () => {
   return axiosInstance.get('/v1/sync/wallet-all');
};

export const syncProjectFundAndWalletBalance = (
   isSyncWallet: 'true' | 'false',
) => {
   return axiosInstance.get(`/v1/sync/sync-all?isSyncWallet=${isSyncWallet}`);
};
