/* eslint-disable @typescript-eslint/no-explicit-any */
import { Skeleton, Tooltip } from 'antd';
import React from 'react';
import CountUp from 'react-countup';
import EthSvg from '../assets/svg/eth';
import numeral from 'numeral';
import BigNumber from 'bignumber.js';
import formatTokenPrice from '../utils/formatTokenPrice';
import EditPencil from '../assets/svg/editPencil';
import ZKSyncSvg from '../assets/svg/zksync';

interface OverviewInfo {
   amountEth?: string | undefined;
   amountUsd?: string | undefined;
   amountZK?: string | undefined;
   tokenPriceUSD?: string | undefined;
   tokenPriceETH?: string | undefined;
   tokenPriceZK?: string | undefined;
   percentage?: string | undefined;
   rangePrice?: [string, string] | undefined;
   amountWithoutCurrency?: string | undefined;
   className?: string;
   loading?: boolean;
   fontSize?: number;
   classNameETH?: string;
   usdSymbolHidden?: boolean;
   onClick?: (data?: any) => void;
}

const OverviewInfo: React.FC<OverviewInfo> = ({
   amountEth,
   amountZK,
   amountUsd,
   tokenPriceUSD,
   tokenPriceETH,
   tokenPriceZK,
   percentage,
   rangePrice,
   amountWithoutCurrency,
   className,
   loading = false,
   fontSize = 24,
   classNameETH,
   usdSymbolHidden = false,
   onClick,
}) => {
   const formatNumber = (value: string, decimals: number) => {
      if (!value || Number.isNaN(value) || isNaN(+value)) {
         return "0";
      }
   
      const bigValue = new BigNumber(value || 0);
   
      if (bigValue.isZero()) {
         return "0";
      }
   
      const absoluteValue = bigValue.abs();
   
      const threshold =
         decimals === 2 ? new BigNumber(0.01) : new BigNumber(0.000001);
      
      if (absoluteValue.isLessThan(threshold)) {
         return "0";
      }
   
      const actualDecimals = absoluteValue.decimalPlaces() as any;
      
      
      const displayDecimals = Math.min(actualDecimals, decimals);
      
      const roundedValue = bigValue.toFixed(displayDecimals, BigNumber.ROUND_HALF_UP)
   
      return numeral(roundedValue).format('0,0.' + '0'.repeat(displayDecimals));
   };
   
   const determineDecimals = (value: string, decimals: number) => {
      if (!value || Number.isNaN(value) || isNaN(+value)) {
         return 0;
      }
   
      const bigValue = new BigNumber(value || 0);
      
      const absoluteValue = bigValue.abs();
   
      const threshold =
         decimals === 2 ? new BigNumber(0.01) : new BigNumber(0.000001);
      
      if (absoluteValue.isLessThan(threshold)) {
         return 0;
      }
   
      const decimalPlaces = absoluteValue.decimalPlaces() as any;
      
      return decimalPlaces > 0 ? Math.min(decimalPlaces, decimals) : 0;
   };
   
   
   return !loading ? (
      <div
         onClick={onClick}
         className={`statistic-number-component !flex gap-2 items-center ${className} ${onClick ? 'cursor-pointer' : ''}`}
      >
         {amountEth && (
            <Tooltip
               placement="bottom"
               title={amountEth ? formatNumber(amountEth, 6) : ''}
            >
               <div
                  className={`flex items-center gap-2 truncate  ${classNameETH}`}
                  style={{ fontSize }}
               >
                  <div>{<EthSvg />}</div>
                  <CountUp
                     end={amountEth ? +amountEth : 0}
                     duration={0.8}
                     separator=","
                     decimals={determineDecimals(amountEth, 6)}
                     decimal="."
                  />
               </div>
            </Tooltip>
         )}
         {amountZK && (
            <Tooltip
               placement="bottom"
               title={amountZK ? formatNumber(amountZK, 2) : ''}
            >
               <div
                  className={`flex items-center gap-2 truncate  ${classNameETH}`}
                  style={{ fontSize }}
               >
                  <div>{<ZKSyncSvg />}</div>
                  <CountUp
                     end={amountZK ? +amountZK : 0}
                     duration={0.8}
                     separator=","
                     decimals={determineDecimals(amountZK, 2)}
                     decimal="."
                  />
               </div>
            </Tooltip>
         )}
         {amountUsd && (
            <Tooltip
               placement="bottom"
               title={amountUsd ? formatNumber(amountUsd, 2) : ''}
            >
               <div className="truncate " style={{ fontSize }}>
                  {!usdSymbolHidden && '$ '}
                  <CountUp
                     className="truncate "
                     end={amountUsd ? +amountUsd : 0}
                     duration={0.8}
                     separator=","
                     decimals={determineDecimals(amountUsd, 2)}
                     decimal="."
                  />
                  {percentage && ` (${percentage}%)`}
               </div>
            </Tooltip>
         )}
         {rangePrice && (
            <Tooltip
               placement="bottom"
               title={
                  rangePrice[0] && rangePrice[1]
                     ? `${formatNumber(rangePrice[0], 2)} => ${formatNumber(rangePrice[1], 2)}`
                     : ''
               }
            >
               <div
                  style={{ fontSize }}
                  className="flex gap-2 items-center truncate"
               >
                  $
                  <CountUp
                     className="truncate"
                     end={rangePrice[0] ? +rangePrice[0] : 0}
                     duration={0.8}
                     separator=","
                     decimals={determineDecimals(rangePrice[0], 2)}
                     decimal="."
                  />
                  <div>
                     <svg
                        width="30px"
                        height="30px"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                     >
                        <path
                           d="M8 6L8 2L10 2L16 8L10 14L8 14L8 10L-1.74845e-07 10L-3.01991e-07 6L8 6Z"
                           fill="#000000"
                        />
                     </svg>
                  </div>
                  <CountUp
                     className="truncate"
                     end={rangePrice[1] ? +rangePrice[1] : 0}
                     duration={0.8}
                     separator=","
                     decimals={determineDecimals(rangePrice[1], 2)}
                     decimal="."
                  />
               </div>
            </Tooltip>
         )}
         {amountWithoutCurrency && (
            <Tooltip
               placement="bottom"
               title={
                  amountWithoutCurrency
                     ? formatNumber(amountWithoutCurrency, 2)
                     : ''
               }
            >
               <div className="truncate " style={{ fontSize }}>
                  <CountUp
                     className="truncate "
                     end={amountWithoutCurrency ? +amountWithoutCurrency : 0}
                     duration={0.8}
                     separator=","
                     decimals={determineDecimals(amountWithoutCurrency, 2)}
                     decimal="."
                  />
               </div>
            </Tooltip>
         )}
         {tokenPriceETH && (
            <div
               className={`flex items-center gap-2 truncate  ${classNameETH}`}
               style={{ fontSize }}
            >
               <div>{<EthSvg />}</div>
               {+tokenPriceETH
                  ? formatTokenPrice(tokenPriceETH)
                  : 0}
            </div>
         )}
         {tokenPriceZK && (
            <div
               className={`flex items-center gap-2 truncate  ${classNameETH}`}
               style={{ fontSize }}
            >
               <div>{<ZKSyncSvg />}</div>
               {formatTokenPrice(tokenPriceZK)}
            </div>
         )}
         {tokenPriceUSD && (
            <div className="truncate" style={{ fontSize }}>
               {!usdSymbolHidden && '$ '}
               {+tokenPriceUSD
                  ? formatTokenPrice(tokenPriceUSD)
                  : 0}{' '}
            </div>
         )}
         {onClick && (
            <div className="h-[21px] w-[21px] flex items-center justify-center rounded-[4px] border border-dashed border-[#0CA7EE]">
               <EditPencil />
            </div>
         )}
      </div>
   ) : (
      <Skeleton.Input active />
   );
};

export default OverviewInfo;
