import React from 'react';

const DataStorageSvg: React.FC<{ fill: string }> = ({ fill }) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
   >
      <path
         d="M28 6.25H4C3.53587 6.25 3.09075 6.43437 2.76256 6.76256C2.43437 7.09075 2.25 7.53587 2.25 8V11C2.25 11.4641 2.43437 11.9092 2.76256 12.2374C3.09075 12.5656 3.53587 12.75 4 12.75H4.25V24C4.25 24.4641 4.43437 24.9092 4.76256 25.2374C5.09075 25.5656 5.53587 25.75 6 25.75H26C26.4641 25.75 26.9092 25.5656 27.2374 25.2374C27.5656 24.9092 27.75 24.4641 27.75 24V12.75H28C28.4641 12.75 28.9092 12.5656 29.2374 12.2374C29.5656 11.9092 29.75 11.4641 29.75 11V8C29.75 7.53587 29.5656 7.09075 29.2374 6.76256C28.9092 6.43437 28.4641 6.25 28 6.25ZM26.25 24C26.25 24.0663 26.2237 24.1299 26.1768 24.1768C26.1299 24.2237 26.0663 24.25 26 24.25H6C5.9337 24.25 5.87011 24.2237 5.82322 24.1768C5.77634 24.1299 5.75 24.0663 5.75 24V12.75H26.25V24ZM28.25 11C28.25 11.0663 28.2237 11.1299 28.1768 11.1768C28.1299 11.2237 28.0663 11.25 28 11.25H4C3.9337 11.25 3.87011 11.2237 3.82322 11.1768C3.77634 11.1299 3.75 11.0663 3.75 11V8C3.75 7.9337 3.77634 7.87011 3.82322 7.82322C3.87011 7.77634 3.9337 7.75 4 7.75H28C28.0663 7.75 28.1299 7.77634 28.1768 7.82322C28.2237 7.87011 28.25 7.9337 28.25 8V11ZM12.25 17C12.25 16.8011 12.329 16.6103 12.4697 16.4697C12.6103 16.329 12.8011 16.25 13 16.25H19C19.1989 16.25 19.3897 16.329 19.5303 16.4697C19.671 16.6103 19.75 16.8011 19.75 17C19.75 17.1989 19.671 17.3897 19.5303 17.5303C19.3897 17.671 19.1989 17.75 19 17.75H13C12.8011 17.75 12.6103 17.671 12.4697 17.5303C12.329 17.3897 12.25 17.1989 12.25 17Z"
         fill={fill}
      />
   </svg>
);

export default DataStorageSvg;
