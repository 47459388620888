/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import axiosInstance from '../axios/axiosInstance';
import { UUID } from 'crypto';
import axiosInstanceForPredictHub from '../axios/axiosInstanceForPredictHub';

export const getJsonByLink = (link: string) => {
   return axios.get(`${link}?t=${new Date().getTime()}`);
};

export const getMMById = (id: UUID) => {
   return axiosInstance.get(`/v1/mm-tools/${id}`);
};

export const saveDataMM = (data: any) => {
   return axiosInstance.post('/v1/mm-tools', data);
};

export const updaeDataMM = (data: any, id: string) => {
   return axiosInstance.put(`/v1/mm-tools/${id}`, data);
};

export const getMMs = (params: any) => {
   return axiosInstance.get('/v1/mm-tools', {
      params,
   });
};

export const duplicateDataMMB = (id: string) => {
   return axiosInstance.post(`/v1/mm-tools/duplicate/${id}`);
};

export const deleteDataMMB = (id: string) => {
   return axiosInstance.delete(`/v1/mm-tools/${id}`);
};

export const updateFileVersion = (fileId: string) => {
   return axiosInstanceForPredictHub.post(
      `/v1/mm-files/update-version/${fileId}`,
   );
};

export const getETHPrice = () => {
   return axiosInstance.get('/v1/contracts/eth-price?chainId=8453');
};
