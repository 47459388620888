/* eslint-disable react/no-unknown-property */
import React from 'react';
import PrinterTerminalComponent from '../../components/PrinterTerminalComponent';
import { Button, Card, Col, Form, FormProps, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import InputNumberComponent from '../../components/InputNumberComponent';
import { validatePositiveNumber } from '../../utils/validatePositiveNumber';
import { FieldNamesType } from 'antd/es/cascader';
import { FieldTypeProjectLaunchForm } from './type/ProjectTypes';

const ProjectLaunchComponent = () => {
   const onFinish: FormProps<FieldNamesType>['onFinish'] = (values) => {
      console.log('Success:', values);
   };

   return (
      <div className="projects-launch-component mt-3">
         <Card className="mb-[20px]">
            <Title className="!mb-4" level={3}>
               Add liquidity
            </Title>
            <Form
               name="project-volume"
               onFinish={onFinish}
               layout="vertical"
               autoComplete="off"
            >
               <Row gutter={20}>
                  <Col span={12}>
                     <Form.Item<FieldTypeProjectLaunchForm>
                        label="ETH amount"
                        name="ethAmount"
                        rules={[
                           {
                              required: true,
                              message: 'Please input ETH amount!',
                           },
                           {
                              validator: validatePositiveNumber,
                           },
                        ]}
                     >
                        <InputNumberComponent />
                     </Form.Item>
                  </Col>
                  <Col span={12}>
                     <Form.Item<FieldTypeProjectLaunchForm>
                        label="NUKE amount"
                        name="tokenAmount"
                        rules={[
                           {
                              required: true,
                              message: 'Please input your amount!',
                           },
                           {
                              validator: validatePositiveNumber,
                           },
                        ]}
                     >
                        <InputNumberComponent allowDecimal />
                     </Form.Item>
                  </Col>
               </Row>
               <Title className="!mb-4" level={3}>
                  Snipe
               </Title>
               <Row gutter={20}>
                  <Col span={12}>
                     <Form.Item<FieldTypeProjectLaunchForm>
                        label="Price Multiplication"
                        name="priceMultiplication"
                        rules={[
                           {
                              required: true,
                              message: 'Please input Price Multiplication!',
                           },
                           {
                              validator: validatePositiveNumber,
                           },
                        ]}
                     >
                        <InputNumberComponent />
                     </Form.Item>
                  </Col>
                  <Col span={12}>
                     <div className="text-sky-400 font-semibold mt-10">
                        Need 4.83 ETH, and have 80% of total NUKE supply
                     </div>
                  </Col>
               </Row>
               <Row gutter={20}>
                  <Col span={12}>
                     <Form.Item<FieldTypeProjectLaunchForm>
                        label="Number of wallets"
                        name="numberOfWallets"
                        rules={[
                           {
                              required: true,
                              message: 'Please input Number of wallets!',
                           },
                           {
                              validator: validatePositiveNumber,
                           },
                        ]}
                     >
                        <InputNumberComponent />
                     </Form.Item>
                  </Col>
               </Row>
               <Button htmlType="submit" type="primary" className="mt-1 mb-6">
                  Launch
               </Button>
            </Form>
            <PrinterTerminalComponent />
         </Card>
      </div>
   );
};

export default ProjectLaunchComponent;
