import React from 'react';

interface CopyProps {
   width?: number;
   height?: number;
}

const Copy: React.FC<CopyProps> = ({ width = 20, height = 20 }) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none"
   >
      <path
         d="M14.375 5.5H3.125C2.95924 5.5 2.80027 5.56585 2.68306 5.68306C2.56585 5.80027 2.5 5.95924 2.5 6.125V17.375C2.5 17.5408 2.56585 17.6997 2.68306 17.8169C2.80027 17.9342 2.95924 18 3.125 18H14.375C14.5408 18 14.6997 17.9342 14.8169 17.8169C14.9342 17.6997 15 17.5408 15 17.375V6.125C15 5.95924 14.9342 5.80027 14.8169 5.68306C14.6997 5.56585 14.5408 5.5 14.375 5.5ZM13.75 16.75H3.75V6.75H13.75V16.75ZM17.5 3.625V14.875C17.5 15.0408 17.4342 15.1997 17.3169 15.3169C17.1997 15.4342 17.0408 15.5 16.875 15.5C16.7092 15.5 16.5503 15.4342 16.4331 15.3169C16.3158 15.1997 16.25 15.0408 16.25 14.875V4.25H5.625C5.45924 4.25 5.30027 4.18415 5.18306 4.06694C5.06585 3.94973 5 3.79076 5 3.625C5 3.45924 5.06585 3.30027 5.18306 3.18306C5.30027 3.06585 5.45924 3 5.625 3H16.875C17.0408 3 17.1997 3.06585 17.3169 3.18306C17.4342 3.30027 17.5 3.45924 17.5 3.625Z"
         fill="white"
      />
   </svg>
);

export default Copy;
