import React, { useState } from 'react';
import {
   Button,
   Card,
   Col,
   InputNumber,
   Radio,
   RadioChangeEvent,
   Row,
   Space,
} from 'antd';
import Title from 'antd/es/typography/Title';
import SpaceWithBackgroundComponent from '../../components/SpaceWithBackgroundComponent';

const ProjectTakeProfitsComponent = () => {
   const [sellOption, setSellOption] = useState(1);

   return (
      <div className="projects-take-profits-component mt-3">
         <Card className="mb-[20px]">
            <Title className="!mb-4" level={3}>
               Close
            </Title>
            <Radio.Group
               className="w-full"
               disabled={false}
               onChange={(e: RadioChangeEvent) => {
                  const value = e.target.value;
                  setSellOption(value);
               }}
               value={sellOption}
            >
               <Space direction="vertical">
                  <Radio value={1}>
                     <div className="flex gap-5 items-center">
                        Soft sell, % token sell
                        <InputNumber<number>
                           disabled={sellOption === 2}
                           defaultValue={100}
                           min={0}
                           max={100}
                           formatter={(value) => `${value}%`}
                           parser={(value) =>
                              value?.replace('%', '') as unknown as number
                           }
                           onChange={(value) => {
                              console.log('changed', value);
                           }}
                        />
                     </div>
                  </Radio>
                  <Radio value={2}>Hard sell</Radio>
               </Space>
               <Row className="my-4 items-center">
                  <Col span={4}>
                     <div className="flex gap-3">
                        <Button>Process</Button>
                        <Button>Pause</Button>
                     </div>
                  </Col>
                  <Col span={20}>
                     <SpaceWithBackgroundComponent>
                        Not start yet
                     </SpaceWithBackgroundComponent>
                  </Col>
               </Row>
            </Radio.Group>
         </Card>
      </div>
   );
};

export default ProjectTakeProfitsComponent;
