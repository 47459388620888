import React from 'react';

const BotManageSvg: React.FC<{ fill: string }> = ({ fill }) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
   >
      <path
         d="M7.33337 7.75C6.09073 7.75 5.08337 8.75736 5.08337 10V24C5.08337 25.2426 6.09073 26.25 7.33337 26.25H25.3334C26.576 26.25 27.5834 25.2426 27.5834 24V10C27.5834 8.75736 26.576 7.75 25.3334 7.75H7.33337ZM3.58337 10C3.58337 7.92893 5.26231 6.25 7.33337 6.25H25.3334C27.4044 6.25 29.0834 7.92893 29.0834 10V24C29.0834 26.0711 27.4044 27.75 25.3334 27.75H7.33337C5.26231 27.75 3.58337 26.0711 3.58337 24V10Z"
         fill={fill}
      />
      <path
         d="M16.3334 1.25C16.7476 1.25 17.0834 1.58579 17.0834 2V7C17.0834 7.41421 16.7476 7.75 16.3334 7.75C15.9192 7.75 15.5834 7.41421 15.5834 7V2C15.5834 1.58579 15.9192 1.25 16.3334 1.25Z"
         fill={fill}
      />
      <path
         d="M21.8334 14.75C22.5237 14.75 23.0834 14.1904 23.0834 13.5C23.0834 12.8096 22.5237 12.25 21.8334 12.25C21.143 12.25 20.5834 12.8096 20.5834 13.5C20.5834 14.1904 21.143 14.75 21.8334 14.75Z"
         fill={fill}
      />
      <path
         d="M20.0834 20C20.0834 20.4142 19.7476 20.75 19.3334 20.75H13.3334C12.9192 20.75 12.5834 20.4142 12.5834 20C12.5834 19.5858 12.9192 19.25 13.3334 19.25H19.3334C19.7476 19.25 20.0834 19.5858 20.0834 20Z"
         fill={fill}
      />
   </svg>
);

export default BotManageSvg;
