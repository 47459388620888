import React from 'react';

const InWalletSvg: React.FC<{ fill: string }> = ({ fill }) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
   >
      <path
         d="M27 8.25H7C6.66848 8.25 6.35054 8.1183 6.11612 7.88388C5.8817 7.64946 5.75 7.33152 5.75 7C5.75 6.66848 5.8817 6.35054 6.11612 6.11612C6.35054 5.8817 6.66848 5.75 7 5.75H24C24.1989 5.75 24.3897 5.67098 24.5303 5.53033C24.671 5.38968 24.75 5.19891 24.75 5C24.75 4.80109 24.671 4.61032 24.5303 4.46967C24.3897 4.32902 24.1989 4.25 24 4.25H7C6.27065 4.25 5.57118 4.53973 5.05546 5.05546C4.53973 5.57118 4.25 6.27065 4.25 7V23C4.25 23.7293 4.53973 24.4288 5.05546 24.9445C5.57118 25.4603 6.27065 25.75 7 25.75H27C27.4641 25.75 27.9092 25.5656 28.2374 25.2374C28.5656 24.9092 28.75 24.4641 28.75 24V10C28.75 9.53587 28.5656 9.09075 28.2374 8.76256C27.9092 8.43437 27.4641 8.25 27 8.25ZM27.25 24C27.25 24.0663 27.2237 24.1299 27.1768 24.1768C27.1299 24.2237 27.0663 24.25 27 24.25H7C6.66848 24.25 6.35054 24.1183 6.11612 23.8839C5.8817 23.6495 5.75 23.3315 5.75 23V9.44875C6.13674 9.64726 6.56529 9.75054 7 9.75H27C27.0663 9.75 27.1299 9.77634 27.1768 9.82322C27.2237 9.87011 27.25 9.9337 27.25 10V24ZM23.75 16.5C23.75 16.7472 23.6767 16.9889 23.5393 17.1945C23.402 17.4 23.2068 17.5602 22.9784 17.6549C22.7499 17.7495 22.4986 17.7742 22.2561 17.726C22.0137 17.6777 21.7909 17.5587 21.6161 17.3839C21.4413 17.2091 21.3223 16.9863 21.274 16.7439C21.2258 16.5014 21.2505 16.2501 21.3451 16.0216C21.4398 15.7932 21.6 15.598 21.8055 15.4607C22.0111 15.3233 22.2528 15.25 22.5 15.25C22.8315 15.25 23.1495 15.3817 23.3839 15.6161C23.6183 15.8505 23.75 16.1685 23.75 16.5Z"
         fill={fill}
      />
   </svg>
);

export default InWalletSvg;
