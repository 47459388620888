/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Card, Col, Form, FormProps, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import { FieldNamesType } from 'antd/es/cascader';
import SwitchComponent from '../../../components/SwitchComponent';
import SpaceWithBackgroundComponent from '../../../components/SpaceWithBackgroundComponent';
import FireSvg from '../../../assets/svg/fire';
import InputNumberComponent from '../../../components/InputNumberComponent';
import { validatePositiveNumber } from '../../../utils/validatePositiveNumber';
import { FieldTypeProjectBoostForm } from '../type/ProjectTypes';
import EyeSvg from '../../../assets/svg/eye';

import LauchSvg from '../../../assets/svg/lauch';
import usePermissions from '../../../hooks/usePermissions';
import { PermissionName } from '../../../constants/permissions';

const ProjectBoostComponent = () => {
   const [screenerState, setScreenerState] = useState<boolean>(false);
   const [dexState, setDexState] = useState<boolean>(false);
   const { hasPermission } = usePermissions();

   const onChangeScreener = (checked: boolean) => {
      setScreenerState(checked);
   };

   const onChangeDex = (checked: boolean) => {
      setDexState(checked);
   };

   const onFinish: FormProps<FieldNamesType>['onFinish'] = (values) => {
      console.log('Success:', values);
   };

   return (
      <div className="projects-boost-component pb-8 mt-3">
         <Card>
            <Form
               name="project-create"
               onFinish={onFinish}
               layout="vertical"
               autoComplete="off"
               disabled={!hasPermission(PermissionName.BOOST_RUNPAUSE)}
            >
               <Title className="!mb-4" level={3}>
                  Dexscreener
               </Title>
               <Row gutter={20}>
                  <Col span={12}>
                     <Form.Item<FieldTypeProjectBoostForm>
                        label={
                           <div className="flex gap-2 hover:gap-3">
                              Visit
                              <EyeSvg />
                           </div>
                        }
                        name="screenerVisit"
                        rules={[
                           {
                              required: true,
                              message: 'Please input your Visit!',
                           },
                           {
                              validator: validatePositiveNumber,
                           },
                        ]}
                     >
                        <InputNumberComponent allowDecimal />
                     </Form.Item>
                  </Col>
               </Row>
               <Row gutter={20}>
                  <Col span={12}>
                     <Form.Item<FieldTypeProjectBoostForm>
                        label={
                           <div className="flex gap-2 hover:gap-3">
                              Rocket
                              <LauchSvg />
                           </div>
                        }
                        name="screenerRocket"
                        rules={[
                           {
                              required: true,
                              message: 'Please input your Rocket!',
                           },
                           {
                              validator: validatePositiveNumber,
                           },
                        ]}
                     >
                        <InputNumberComponent allowDecimal />
                     </Form.Item>
                  </Col>
                  <Col span={12}>
                     <Form.Item<FieldTypeProjectBoostForm>
                        label={
                           <div className="flex gap-2 hover:gap-3">
                              Fire
                              <FireSvg />
                           </div>
                        }
                        name="screenerFire"
                        rules={[
                           {
                              required: true,
                              message: 'Please input your Fire!',
                           },
                           {
                              validator: validatePositiveNumber,
                           },
                        ]}
                     >
                        <InputNumberComponent allowDecimal />
                     </Form.Item>
                  </Col>
               </Row>
               <Row className="mb-4">
                  <Col span={4}>
                     <SwitchComponent
                        disabled={!hasPermission(PermissionName.BOOST_RUNPAUSE)}
                        value={screenerState}
                        onChange={onChangeScreener}
                     />
                  </Col>
                  <Col span={20}>
                     <SpaceWithBackgroundComponent>
                        <Row gutter={12}>
                           <Col span={12} className="font-bold text-lg">
                              Last update: 11:04 27/06/2024
                           </Col>
                           <Col span={12}>
                              <Row gutter={12}>
                                 <Col
                                    span={8}
                                    className="flex gap-2 hover:gap-3 items-center justify-center font-bold text-lg"
                                 >
                                    300 <EyeSvg />
                                 </Col>
                                 <Col
                                    span={8}
                                    className="flex gap-2 hover:gap-3 items-center justify-center font-bold text-lg"
                                 >
                                    100 <LauchSvg />
                                 </Col>
                                 <Col
                                    span={8}
                                    className="flex gap-2 hover:gap-3 items-center justify-center font-bold text-lg"
                                 >
                                    100 <FireSvg />
                                 </Col>
                              </Row>
                           </Col>
                        </Row>
                     </SpaceWithBackgroundComponent>
                  </Col>
               </Row>
               <Title className="!mb-4" level={3}>
                  Dextools
               </Title>
               <Row gutter={20}>
                  <Col span={12}>
                     <Form.Item<FieldTypeProjectBoostForm>
                        label={
                           <div className="flex gap-2 hover:gap-3">
                              Visit
                              <EyeSvg />
                           </div>
                        }
                        name="toolVisit"
                        rules={[
                           {
                              required: true,
                              message: 'Please input your Visit!',
                           },
                           {
                              validator: validatePositiveNumber,
                           },
                        ]}
                     >
                        <InputNumberComponent allowDecimal />
                     </Form.Item>
                  </Col>
               </Row>
               <Row className="mb-4">
                  <Col span={4}>
                     <SwitchComponent
                        disabled={!hasPermission(PermissionName.BOOST_RUNPAUSE)}
                        value={dexState}
                        onChange={onChangeDex}
                     />
                  </Col>
                  <Col span={20}>
                     <SpaceWithBackgroundComponent>
                        <Row gutter={12}>
                           <Col span={12} className="font-bold text-lg">
                              Last update: 11:04 27/06/2024
                           </Col>
                           <Col span={12}>
                              <Row gutter={12}>
                                 <Col
                                    span={8}
                                    className="flex gap-2 hover:gap-3 items-center justify-center font-bold text-lg"
                                 >
                                    300 <EyeSvg />
                                 </Col>
                              </Row>
                           </Col>
                        </Row>
                     </SpaceWithBackgroundComponent>
                  </Col>
               </Row>
            </Form>
         </Card>
      </div>
   );
};

export default ProjectBoostComponent;
