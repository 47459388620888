import React from 'react';

const OverviewSvg: React.FC<{ fill: string }> = ({ fill }) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
   >
      <path
         d="M28 25.25H26.75V5C26.75 4.80109 26.671 4.61032 26.5303 4.46967C26.3897 4.32902 26.1989 4.25 26 4.25H19C18.8011 4.25 18.6103 4.32902 18.4697 4.46967C18.329 4.61032 18.25 4.80109 18.25 5V10.25H12C11.8011 10.25 11.6103 10.329 11.4697 10.4697C11.329 10.6103 11.25 10.8011 11.25 11V16.25H6C5.80109 16.25 5.61032 16.329 5.46967 16.4697C5.32902 16.6103 5.25 16.8011 5.25 17V25.25H4C3.80109 25.25 3.61032 25.329 3.46967 25.4697C3.32902 25.6103 3.25 25.8011 3.25 26C3.25 26.1989 3.32902 26.3897 3.46967 26.5303C3.61032 26.671 3.80109 26.75 4 26.75H28C28.1989 26.75 28.3897 26.671 28.5303 26.5303C28.671 26.3897 28.75 26.1989 28.75 26C28.75 25.8011 28.671 25.6103 28.5303 25.4697C28.3897 25.329 28.1989 25.25 28 25.25ZM19.75 5.75H25.25V25.25H19.75V5.75ZM12.75 11.75H18.25V25.25H12.75V11.75ZM6.75 17.75H11.25V25.25H6.75V17.75Z"
         fill={fill}
      />
   </svg>
);

export default OverviewSvg;
