import { Switch } from 'antd';
import { SwitchChangeEventHandler } from 'antd/es/switch';
import React from 'react';

interface SwitchComponentProps {
   value: boolean;
   onChange: SwitchChangeEventHandler;
   disabled?: boolean;
}

const SwitchComponent: React.FC<SwitchComponentProps> = ({
   value,
   onChange,
   disabled = false,
}) => {
   return (
      <div className="flex items-center h-full">
         <span className="font-semibold text-lg w-20">Enable</span>
         <Switch disabled={disabled} value={value} onChange={onChange} />
      </div>
   );
};

export default SwitchComponent;
