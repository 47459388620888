/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import BreadcrumbComponent from '../../components/BreadcrumbComponent';
import Title from 'antd/es/typography/Title';
import {
   Button,
   Card,
   Col,
   message,
   Popconfirm,
   Row,
   Table,
   TableProps,
} from 'antd';
import { SelectCurrency } from '../Projects/type/ProjectTypes';
import LoadingComponent from '../../components/LoadingComponent';
import HeadingWithTooltipComponent from '../../components/TitleWithTooltipComponent';
import OverviewInfo from '../../components/OverviewInfo';
import {
   getProjectFund,
   getTotalBalance,
} from '../../services/projectServices';
import usePaginationConfig from '../../hooks/usePaginationConfig';
import { SorterResult } from 'antd/es/table/interface';
import useSortOrder from '../../hooks/useSortOrder';
import {
   AQUAFOX_ID,
   DEFAULT_DATETIME_FORMAT,
   DEFAULT_PAGINATE_SIZE,
   ERROR_MESSAGE,
} from '../../constants/common';
import OpenSvg from '../../assets/svg/open';
import EthSvg from '../../assets/svg/eth';
import formatCurrency from '../../utils/currencyConverter';
import _ from 'lodash';
import { SyncOutlined } from '@ant-design/icons';
import {
   syncProjectFund,
   syncProjectFundAndWalletBalance,
   syncTotalFund,
} from '../../services/syncServices';
import moment from 'moment';
import BigNumber from 'bignumber.js';

const projectFundBreadcrumbs = [
   {
      title: 'All Company',
      link: `/`,
   },
   {
      title: 'Fund Manage',
   },
];

interface TotalFund {
   totalEth: string;
   totalWeth: string;
   totalBoth: string;
   totalEthExceptPool: string;
   totalWethExceptPool: string;
   totalBothExceptPool: string;
}

interface DataTypeProjectFund {
   createdBy: null;
   updatedBy: null;
   deletedBy: null;
   createdAt: string;
   updatedAt: string;
   deletedAt: null;
   id: string;
   projectId: string;
   balanceEthDeployer: string;
   balanceEthPool: string;
   balanceEthTrader: string;
   balanceEthOperatorAuto: string;
   balanceEthOperatorManual: string;
   balanceEthTradeContract: string;
   totalETH: string;
   totalETHExceptPool: string;
   project: Project;
}

interface Project {
   createdBy: string;
   updatedBy: string;
   deletedBy: null;
   createdAt: string;
   updatedAt: string;
   deletedAt: null;
   id: string;
   name: string;
   tokenAddress: string;
   tokenSymbol: string;
   tokenName: string;
   tokenDecimal: number;
   tokenTotalSupply: string;
   status: string;
   space: string;
   predicthubStrateryId: null;
   chainId: number;
   creatorId: string;
   deployerAddress: string;
   operatorAddresses: string[];
   tradeAddress: string;
   pairAddress: string;
   ownerAddress: string;
   version: string;
}

const FundComponent: React.FC = () => {
   const [fundCurrency, setFundCurrency] = useState<SelectCurrency>('ETH');
   const [totalFundData, setTotalFundData] = useState<TotalFund>({
      totalEth: '0',
      totalWeth: '0',
      totalBoth: '0',
      totalEthExceptPool: '0',
      totalWethExceptPool: '0',
      totalBothExceptPool: '0',
   });
   const [fetchingTotalFund, setFetchingFund] = useState<boolean>(false);
   const [syncingProject, setSyncingProject] = useState<boolean>(false);
   const [syncingTotalFund, setSyncingTotalFund] = useState<boolean>(false);
   const [syncingAll, setSyncingAll] = useState<boolean>(false);
   const isFetchingRef = useRef(false);
   const isFetchingTableRef = useRef(false);
   const [page, setPage] = useState<number>(1);
   const [total, setTotal] = useState<number>(0);
   const paginationConfig = usePaginationConfig(total, page, setPage);
   const [fetchingProjectFund, setFetchingProjectFund] =
      useState<boolean>(false);
   const { sortOrder, sortColumns, updateSortOrder, getSortParams } =
      useSortOrder<DataTypeProjectFund>();
   const [dataProjectFund, setDataProjectFund] = useState<
      DataTypeProjectFund[]
   >([]);
   const [lastSync, setLastSync] = useState('');
   const [isFetchingInterval, setFetchingInterval] = useState<boolean>(false);
   const [openPopconfirm, setOpenPopconfirm] = useState<boolean>(false);
   const handleTableChange = (
      pagination: any,
      filters: any,
      sorter:
         | SorterResult<DataTypeProjectFund>
         | SorterResult<DataTypeProjectFund>[],
   ) => {
      updateSortOrder(sorter);
   };

   const searchParamsConverter = () => {
      const paramsConverter: any = {};
      return paramsConverter;
   };

   const fetchTotalFund = async () => {
      if (isFetchingRef.current) {
         return;
      }
      setFetchingFund(true);
      isFetchingRef.current = true;
      try {
         const res = await getTotalBalance();
         setFetchingFund(false);
         const totalFund = res?.data?.data as TotalFund;
         isFetchingRef.current = false;
         setTotalFundData({
            ...totalFund,
            totalBoth: new BigNumber(totalFund.totalEth)
               .plus(totalFund.totalWeth)
               .toString(),
            totalBothExceptPool: new BigNumber(totalFund.totalEthExceptPool)
               .plus(totalFund.totalWethExceptPool)
               .toString(),
         });
      } catch (err) {
         setFetchingFund(false);
         isFetchingRef.current = false;
      }
   };

   useEffect(() => {
      fetchTotalFund();
   }, []);

   const fetchProjectFund = async () => {
      try {
         setFetchingProjectFund(true);
         isFetchingTableRef.current = true;
         const res = await getProjectFund({
            page,
            limit: DEFAULT_PAGINATE_SIZE,
            orderBy: 'createdAt',
            sortBy: 'asc',
            ...getSortParams(),
            ...searchParamsConverter(),
         });
         const projectFund = res?.data?.data;
         setDataProjectFund(
            (projectFund?.items || []).map((item: DataTypeProjectFund) => ({
               ...item,
               projectName: item?.project?.name,
            })),
         );
         setTotal(projectFund?.meta?.total);
         setFetchingProjectFund(false);
         isFetchingTableRef.current = false;
      } catch (error) {
         setFetchingProjectFund(false);
         isFetchingTableRef.current = false;
         message.error(ERROR_MESSAGE);
      }
   };

   useEffect(() => {
      fetchProjectFund();
   }, [page, sortOrder]);

   const columns: TableProps<DataTypeProjectFund>['columns'] = [
      {
         title: 'Project Name',
         dataIndex: 'projectName',
         key: 'projectName',
      },
      {
         title: 'Deployer',
         align: 'right',
         dataIndex: 'balanceEthDeployer',
         key: 'balanceEthDeployer',
         // ...sortColumns('balanceEthDeployer'),
         render: (item) => (
            <div className="flex gap-1 justify-end">
               <div>
                  <EthSvg width={20} height={20} />
               </div>
               {formatCurrency(item, 'ETH')}
            </div>
         ),
      },
      {
         title: 'Operator Auto',
         align: 'right',
         dataIndex: 'balanceEthOperatorAuto',
         key: 'balanceEthOperatorAuto',
         // ...sortColumns('balanceEthOperatorAuto'),
         render: (item) => (
            <div className="flex gap-1 justify-end">
               <div>
                  <EthSvg width={20} height={20} />
               </div>
               {formatCurrency(item, 'ETH')}
            </div>
         ),
      },
      {
         title: 'Operator Manual',
         align: 'right',
         dataIndex: 'balanceEthOperatorManual',
         key: 'balanceEthOperatorManual',
         // ...sortColumns('balanceEthOperatorManual'),
         render: (item) => (
            <div className="flex gap-1 justify-end">
               <div>
                  <EthSvg width={20} height={20} />
               </div>
               {formatCurrency(item, 'ETH')}
            </div>
         ),
      },
      {
         title: 'Pool',
         align: 'right',
         dataIndex: 'balanceEthPool',
         key: 'balanceEthPool',
         // ...sortColumns('balanceEthPool'),
         render: (item) => (
            <div className="flex gap-1 justify-end">
               <div>
                  <EthSvg width={20} height={20} />
               </div>
               {formatCurrency(item, 'ETH')}
            </div>
         ),
      },
      {
         title: 'Trade Contract',
         align: 'right',
         dataIndex: 'balanceEthTradeContract',
         key: 'balanceEthTradeContract',
         // ...sortColumns('balanceEthTradeContract'),
         render: (item) => (
            <div className="flex gap-1 justify-end">
               <div>
                  <EthSvg width={20} height={20} />
               </div>
               {formatCurrency(item, 'ETH')}
            </div>
         ),
      },
      {
         title: 'Trader',
         align: 'right',
         dataIndex: 'balanceEthTrader',
         key: 'balanceEthTrader',
         // ...sortColumns('balanceEthTrader'),
         render: (item) => (
            <div className="flex gap-1 justify-end">
               <div>
                  <EthSvg width={20} height={20} />
               </div>
               {formatCurrency(item, 'ETH')}
            </div>
         ),
      },
      {
         title: 'Total',
         align: 'right',
         dataIndex: 'totalETH',
         key: 'totalETH',
         // ...sortColumns('totalETH'),
         render: (item) => (
            <div className="flex gap-1 justify-end">
               <div>
                  <EthSvg width={20} height={20} />
               </div>
               {formatCurrency(item, 'ETH')}
            </div>
         ),
      },
      {
         title: 'Total Except Pool',
         align: 'right',
         dataIndex: 'totalETHExceptPool',
         key: 'totalETHExceptPool',
         // ...sortColumns('totalETHExceptPool'),
         render: (item) => (
            <div className="flex gap-1 justify-end">
               <div>
                  <EthSvg width={20} height={20} />
               </div>
               {formatCurrency(item, 'ETH')}
            </div>
         ),
      },
   ];

   const fetchBoth = async (isTrigger = false) => {
      if ((isFetchingRef.current || isFetchingTableRef.current) && !isTrigger) {
         return;
      }

      if (!_.isEmpty(searchParamsConverter()) && !isTrigger) {
         return;
      }

      try {
         setFetchingInterval(true);
         isFetchingRef.current = true;
         isFetchingTableRef.current = true;
         const [dataTotalFund, dataProjectFund] = await Promise.all([
            getTotalBalance(),
            getProjectFund({
               page,
               limit: DEFAULT_PAGINATE_SIZE,
               orderBy: 'createdAt',
               sortBy: 'asc',
               ...getSortParams(),
               ...searchParamsConverter(),
            }),
         ]);
         const totalFund = dataTotalFund?.data?.data;
         setTotalFundData({
            ...totalFund,
            totalBoth: new BigNumber(totalFund.totalEth)
               .plus(totalFund.totalWeth)
               .toString(),
            totalBothExceptPool: new BigNumber(totalFund.totalEthExceptPool)
               .plus(totalFund.totalWethExceptPool)
               .toString(),
         });
         const projectsFund = dataProjectFund?.data?.data;
         setDataProjectFund(
            (projectsFund?.items || []).map((item: DataTypeProjectFund) => ({
               ...item,
               projectName: item?.project?.name,
            })),
         );
         setTotal(projectsFund?.meta?.total);
         setFetchingInterval(false);
         isFetchingRef.current = false;
         isFetchingTableRef.current = false;
      } catch (err) {
         isFetchingRef.current = false;
         isFetchingTableRef.current = false;
         setFetchingInterval(false);
      }
   };

   useEffect(() => {
      const intervalId = setInterval(fetchBoth, 20000);
      return () => clearInterval(intervalId);
   }, []);

   const syncFund = async () => {
      try {
         setSyncingTotalFund(true);
         const res = await syncTotalFund();
         setLastSync(`Total Fund: ${moment().format(DEFAULT_DATETIME_FORMAT)}`);
         message.success(res?.data?.data || 'Sync Requested Success');
         setSyncingTotalFund(false);
      } catch (err) {
         setSyncingTotalFund(false);
         message.error(ERROR_MESSAGE);
      }
   };

   const syncProject = async () => {
      try {
         setSyncingProject(true);
         const res = await syncProjectFund();
         setLastSync(
            `Project Fund: ${moment().format(DEFAULT_DATETIME_FORMAT)}`,
         );
         message.success(res?.data?.data || 'Sync Requested Success');
         setSyncingProject(false);
      } catch (err) {
         setSyncingProject(false);
         message.error(ERROR_MESSAGE);
      }
   };

   const syncAll = async (isSyncWallet: 'true' | 'false' = 'false') => {
      if (syncingAll) {
         return;
      }
      try {
         setSyncingAll(true);
         const res = await syncProjectFundAndWalletBalance(isSyncWallet);
         await fetchBoth();
         setLastSync(`: ${moment().format(DEFAULT_DATETIME_FORMAT)}`);
         message.success(res?.data?.data || 'Sync Requested Success');
         setSyncingAll(false);
      } catch (err) {
         setSyncingAll(false);
         message.error(ERROR_MESSAGE);
      }
   };

   const handleConfirm = async () => {
      setOpenPopconfirm(false);
      await syncAll('true');
   };

   const handleCancel = async () => {
      setOpenPopconfirm(false);
      await syncAll();
   };

   return (
      <div className="container py-5 mx-auto h-full">
         <div className="fund-component pb-8">
            <div className="flex justify-between items-center">
               <BreadcrumbComponent items={projectFundBreadcrumbs} />
            </div>
            <div className="flex gap-3 justify-between items-center mt-[7px] mb-[23px] flex-wrap">
               <Title
                  level={3}
                  className="!mb-0 flex items-center max-sm:w-full"
               >
                  Total ETH and WETH
               </Title>

               <div className="flex gap-3 items-center max-sm:flex-row-reverse">
                  {/* {lastSync && `Last Sync ${lastSync}`}
                  <Button
                     loading={syncingProject}
                     type="primary"
                     onClick={syncProject}
                  >
                     Sync Project
                  </Button>
                  <Button loading={syncingTotalFund} onClick={syncFund}>
                     Sync Total Fund
                  </Button> */}

                  {isFetchingInterval && (
                     <SyncOutlined spin style={{ fontSize: 24 }} />
                  )}

                  {dataProjectFund?.[0]?.updatedAt && !syncingAll && (
                     <div>
                        Last Sync:{' '}
                        {moment(
                           dataProjectFund?.[0]?.updatedAt as string,
                        ).format(DEFAULT_DATETIME_FORMAT)}
                     </div>
                  )}
                  <Button
                     type="primary"
                     icon={<SyncOutlined spin={syncingAll} />}
                     iconPosition="end"
                     onClick={handleCancel}
                  >
                     Refresh
                  </Button>
                  {/* <Select
                     value={topbuyersCurrency}
                     onChange={(value: SelectCurrency) =>
                        setTopbuyersCurrency(value)
                     }
                     className="w-24 shadow-small rounded-[10px]"
                  >
                     <Select.Option value="USD">USDT</Select.Option>
                     <Select.Option value="ETH">ETH</Select.Option>
                  </Select> */}
               </div>
            </div>
            <Card className="mt-4">
               <Row gutter={[20, 20]}>
                  <Col xs={24} sm={12} md={8}>
                     <LoadingComponent
                        loading={
                           totalFundData?.totalEth ? false : fetchingTotalFund
                        }
                     >
                        <HeadingWithTooltipComponent>
                           Total
                        </HeadingWithTooltipComponent>
                        <div className="flex gap-2 items-center justify-center">
                           {fundCurrency === 'USD' ? (
                              <OverviewInfo
                                 amountUsd={totalFundData?.totalBoth || '0'}
                              />
                           ) : (
                              <OverviewInfo
                                 amountEth={totalFundData?.totalBoth || '0'}
                              />
                           )}
                        </div>
                     </LoadingComponent>
                  </Col>
                  <Col xs={24} sm={12} md={8}>
                     <LoadingComponent
                        loading={
                           totalFundData?.totalEth ? false : fetchingTotalFund
                        }
                     >
                        <HeadingWithTooltipComponent>
                           Total Except Pool
                        </HeadingWithTooltipComponent>
                        <div className="flex gap-2 items-center justify-center">
                           {fundCurrency === 'USD' ? (
                              <OverviewInfo
                                 amountUsd={
                                    totalFundData?.totalBothExceptPool || '0'
                                 }
                              />
                           ) : (
                              <OverviewInfo
                                 amountEth={
                                    totalFundData?.totalBothExceptPool || '0'
                                 }
                              />
                           )}
                        </div>
                     </LoadingComponent>
                  </Col>
                  {/* <Col xs={24} sm={12} md={8}>
                        <Tooltip
                           title={syncingAll ? null : 'Click to sync all'}
                           placement="bottom"
                        >
                           <Card
                              onClick={syncAll}
                              className="h-full flex items-center justify-center gap-3 cursor-pointer"
                           >
                              {syncingAll ? (
                                 <div className="flex gap-3">
                                    <Spin />
                                    Syncing
                                 </div>
                              ) : (
                                 <Title level={5} className="!mb-0 flex gap-2">
                                    {lastSync && `Last Sync ${lastSync}`}
                                    <SyncOutlined style={{ fontSize: 18 }} />
                                 </Title>
                              )}
                           </Card>
                        </Tooltip>
                     </Col> */}
               </Row>
            </Card>
            <div>
               <Title
                  level={3}
                  className="!mb-0 flex py-5 items-center max-sm:w-full"
               >
                  Project Fund
               </Title>
               <Table
                  loading={fetchingProjectFund}
                  columns={columns}
                  dataSource={dataProjectFund}
                  bordered
                  pagination={paginationConfig}
                  rowClassName={() => 'cursor-pointer'}
                  onChange={handleTableChange}
                  scroll={{ x: 'max-content' }}
                  onRow={(record) => {
                     return {
                        onClick: () => {
                           window.open(
                              `company/${AQUAFOX_ID}/project/${record?.projectId}/monitor`,
                              '_blank',
                              'noopener,noreferrer',
                           );
                        },
                     };
                  }}
               />
            </div>
         </div>
      </div>
   );
};

export default FundComponent;
