/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';
import {
   Button,
   Checkbox,
   Form,
   Input,
   InputRef,
   message,
   Radio,
   RadioChangeEvent,
   Space,
   Table,
   TableColumnType,
   TableProps,
} from 'antd';
import Title from 'antd/es/typography/Title';
import { FilterDropdownProps, SorterResult } from 'antd/es/table/interface';
import {
   DataTypeStrategyUserTx,
   DataTypeStrateryUserTx,
   DataTypeUserTx,
   RangeValue,
   SearchParam,
   TransactionType,
} from '../../type/ProjectTypes';
import { SearchOutlined } from '@ant-design/icons';
// import Highlighter from 'react-highlight-words';
import InputNumberComponent from '../../../../components/InputNumberComponent';
import { validatePositiveNumber } from '../../../../utils/validatePositiveNumber';
import { RootState } from '../../../../redux/store';
import { useSelector } from 'react-redux';
import { DEFAULT_PAGINATE_SIZE, ERROR_MESSAGE } from '../../../../constants/common';
import { getStategyUserTransactions, getUserTransactions } from '../../../../services/monitorService';
import usePaginationConfig from '../../../../hooks/usePaginationConfig';
import formatCurrency from '../../../../utils/currencyConverter';
import TransactionTypesRender from '../../../../utils/transactionTypesRender';
import amountWithIconRender from '../../../../utils/amountWithIconRender';
import useSortOrder from '../../../../hooks/useSortOrder';
import { useParams } from 'react-router-dom';
import { transactionDexscreenerScan } from '../../../../utils/linkScan';
import rangeAmount from '../../../../utils/rangeAmount';
import { ETH_ADDRESS_REGEX } from '../../../../constants/regex';
import { dateConverter } from '../../../../utils/dateTimeStampConverter';
import {
   DolphinFilter,
   FilterAnimals,
   FilterIconRender,
   FishFilter,
   ShrimpFilter,
   WhaleFilter,
} from '../../../../utils/filterIconRender';
import OpenSvg from '../../../../assets/svg/open';
import AddressCopyableComponent from '../../../../components/AddressCopyableComponent';

type DataIndex = keyof DataTypeStrategyUserTx;

const StrateryUserTransactionComponent = () => {
   const [formETH] = Form.useForm();

   const [formToken] = Form.useForm();

   const [formMaker] = Form.useForm();

   const [dataUserTx, setDataUserTx] = useState<DataTypeStrategyUserTx[]>([]);

   const [searchParams, setSearchParams] = useState<SearchParam[]>([]);

   const [fetchingUserTX, setFetchingUserTX] = useState<boolean>(false);

   const [page, setPage] = useState<number>(1);

   const [total, setTotal] = useState<number>(0);

   const [isTrigger, setTrigger] = useState<boolean>(false);

   const paginationConfig = usePaginationConfig(total, page, setPage);

   const searchInput = useRef<InputRef>(null);

   const { project } = useSelector((state: RootState) => state.projects);

   const params = useParams();

   const { sortOrder, sortColumns, updateSortOrder, getSortParams } =
      useSortOrder<DataTypeStrategyUserTx>();

   const handleTableChange = (
      pagination: any,
      filters: any,
      sorter: SorterResult<DataTypeStrategyUserTx> | SorterResult<DataTypeStrategyUserTx>[],
   ) => {
      updateSortOrder(sorter);
   };

   const searchParamsConverter = () => {
      const paramsConverter: any = {};
      const side = searchParams.find(
         (s) => s.type === 'side',
      );
      if (side && Array.isArray(side.value)) {
         paramsConverter.side = side.value
            .map((x) => x.toUpperCase())
            .join(',');
      }
      const amountEth = searchParams.find((s) => s.type === 'amountEth');
      if (amountEth && amountEth.value && typeof amountEth.value === 'object') {
         if ('min' in amountEth.value && 'max' in amountEth.value) {
            const value = amountEth.value as RangeValue;
            paramsConverter.minEth = value.min;
            paramsConverter.maxEth = value.max;
         }
      }
      const amountToken = searchParams.find((s) => s.type === 'amountToken');
      if (
         amountToken &&
         amountToken.value &&
         typeof amountToken.value === 'object'
      ) {
         const value = amountToken.value as RangeValue;
         paramsConverter.minToken = value.min;
         paramsConverter.maxToken = value.max;
      }
      const maker = searchParams.find(
         (s) => s.type === 'maker',
      );
      if (maker && maker.value) {
         paramsConverter.maker = maker.value;
      }
      const amountUsd = searchParams.find((s) => s.type === 'amountUsd');
      if (amountUsd && amountUsd.value && typeof amountUsd.value === 'string') {
         const value = amountUsd.value as FilterAnimals;
         const range = rangeAmount(value.toLowerCase());
         paramsConverter.minUsd = range.min;
         if (range.max) {
            paramsConverter.maxUsd = range.max;
         }
      }
      // if (amountUsd === 1) {
      //    paramsConverter
      // }
      return paramsConverter;
   };

   const updateSearchParams = (newItem: SearchParam) => {
      setSearchParams((prevParams) => {
         if (newItem.value === undefined) {
            return prevParams.filter((item) => item.type !== newItem.type);
         } else {
            return [
               ...prevParams.filter((item) => item.type !== newItem.type),
               newItem,
            ];
         }
      });
   };

   const handleSearchText = (
      selectedKeys: string[],
      close: FilterDropdownProps['close'],
      dataIndex: DataIndex,
   ) => {
      close();
      updateSearchParams({
         type: dataIndex,
         value: formMaker.getFieldValue('maker'),
      });
   };

   const handleReset = (
      clearFilters: () => void,
      confirm: FilterDropdownProps['confirm'],
      dataIndex: DataIndex,
   ) => {
      formMaker.resetFields();
      clearFilters();
      confirm();
      updateSearchParams({
         type: dataIndex,
         value: undefined,
      });
   };

   useEffect(() => {
      const fetchUserTX = async () => {
         try {
            setFetchingUserTX(true);
            const res = await getStategyUserTransactions(
               {
                  projectId: params.id ? +params.id : 0,
                  page,
                  limit: DEFAULT_PAGINATE_SIZE,
                  side: 'BUY,SELL',
                  orderBy: 'createdAt',
                  ...getSortParams(),
                  ...searchParamsConverter(),
               },
            );
            const userTxs = res?.data?.data;
            setDataUserTx(userTxs?.items);
            setTotal(userTxs?.meta?.total);
            setFetchingUserTX(false);
         } catch (error) {
            setFetchingUserTX(false);
            message.error(ERROR_MESSAGE);
         }
      };
      fetchUserTX();
   }, [page, sortOrder, searchParams]);

   const getColumnSearchTextProps = (
      dataIndex: DataIndex,
   ): TableColumnType<DataTypeUserTx> => ({
      filterDropdown: ({
         setSelectedKeys,
         selectedKeys,
         confirm,
         clearFilters,
         close,
      }) => (
         <div
            style={{
               padding: 12,
               paddingTop: 22,
               paddingBottom: 20,
               width: 300,
            }}
            onKeyDown={(e) => e.stopPropagation()}
         >
            <Form
               form={formMaker}
               size="middle"
               onFinish={(values) => {
                  setSelectedKeys(values.maker ? [values.maker] : []);
                  handleSearchText(
                     selectedKeys as string[],
                     confirm,
                     dataIndex,
                  );
               }}
            >
               <Form.Item
                  name="maker"
                  rules={[
                     {
                        required: true,
                        message: 'Please input your address!',
                     },
                     {
                        pattern: ETH_ADDRESS_REGEX,
                        message: 'Please input valid Address!',
                     },
                  ]}
               >
                  <Input
                     ref={searchInput}
                     placeholder={`Search Maker`}
                     value={selectedKeys[0]}
                  />
               </Form.Item>
            </Form>

            <div className="flex justify-between gap-2">
               <Button
                  type="primary"
                  className="w-full"
                  onClick={() => formMaker.submit()}
                  icon={<SearchOutlined />}
                  size="middle"
               >
                  Search
               </Button>
               <Button
                  onClick={() => {
                     clearFilters &&
                        handleReset(clearFilters, close, dataIndex);
                  }}
                  className="w-full"
                  size="middle"
               >
                  Reset
               </Button>
            </div>
         </div>
      ),
      filterIcon: () => FilterIconRender(searchParams, dataIndex, 'text'),
      onFilterDropdownOpenChange: (visible) => {
         if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
         }
      },
      render: (text) =>
         //   searchedColumn === dataIndex ? (
         //     <Highlighter
         //       highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
         //       searchWords={[searchText]}
         //       autoEscape
         //       textToHighlight={text ? text.toString() : ''}
         //     />
         //   ) : (
         text,
      //   ),
   });

   const getColumnSearchAnimalSingleProps = (
      dataIndex: DataIndex,
   ): TableColumnType<DataTypeUserTx> => ({
      filterDropdown: ({
         setSelectedKeys,
         selectedKeys,
         confirm,
         clearFilters,
         // close,
      }) => (
         <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Radio.Group
               disabled={false}
               onChange={(e: RadioChangeEvent) => {
                  const value = e.target.value;
                  setSelectedKeys(value);
                  if (value === 1) {
                     confirm();
                     clearFilters && clearFilters();
                     updateSearchParams({
                        type: 'amountUsd',
                        value: undefined,
                     });
                  } else {
                     confirm();
                     updateSearchParams({
                        type: 'amountUsd',
                        value,
                     });
                  }
               }}
               value={selectedKeys}
            >
               <Space direction="vertical">
                  <Radio value={1}>All</Radio>
                  <Radio value="shrimp">
                     <ShrimpFilter />
                  </Radio>
                  <Radio value="fish">
                     <FishFilter />
                  </Radio>
                  <Radio value="dolphin">
                     <DolphinFilter />
                  </Radio>
                  <Radio value="whale">
                     <WhaleFilter />
                  </Radio>
               </Space>
            </Radio.Group>
         </div>
      ),
      filterIcon: FilterIconRender(searchParams, 'amountUsd', 'animal'),
      render: (text) => text,
   });

   const getColumnSearchMultiProps = (
      dataIndex: DataIndex,
   ): TableColumnType<DataTypeUserTx> => ({
      filterDropdown: ({
         setSelectedKeys,
         selectedKeys,
         // confirm,
         clearFilters,
         // close,
      }) => (
         <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <div style={{ padding: 8 }}>
               <Checkbox.Group
                  onChange={(checkedValues) => {
                     const value = checkedValues;
                     setSelectedKeys(value);
                     if (!value?.length) {
                        // confirm();
                        clearFilters && clearFilters();
                        updateSearchParams({
                           type: dataIndex,
                           value: undefined,
                        });
                     } else {
                        // confirm();
                        updateSearchParams({
                           type: dataIndex,
                           value,
                        });
                     }
                  }}
                  value={selectedKeys as string[]}
               >
                  <Space direction="vertical">
                     <Checkbox value="Buy">Buy</Checkbox>
                     <Checkbox value="Sell">Sell</Checkbox>
                  </Space>
               </Checkbox.Group>
            </div>
         </div>
      ),
      filterIcon: () => FilterIconRender(searchParams, dataIndex, 'multiple'),
      render: (text) => text,
   });

   const getColumnSearchETHRangeProps = (
      dataIndex: DataIndex,
   ): TableColumnType<DataTypeUserTx> => ({
      filterDropdown: ({
         setSelectedKeys,
         // selectedKeys,
         confirm,
         clearFilters,
         // close,
      }) => (
         <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <div style={{ padding: 8, width: 300 }}>
               <Form
                  form={formETH}
                  size="middle"
                  onFinish={(values) => {
                     confirm();
                     setSelectedKeys(values);
                     updateSearchParams({
                        type: dataIndex,
                        value: values,
                     });
                  }}
               >
                  <Form.Item
                     name="min"
                     rules={[
                        {
                           required: true,
                           message: 'Please input your Amount!',
                        },
                        {
                           validator: validatePositiveNumber,
                        },
                     ]}
                  >
                     <InputNumberComponent
                        allowDecimal
                        addonBefore="ETH"
                        placeholder="ETH"
                     />
                  </Form.Item>
                  <Form.Item
                     name="max"
                     rules={[
                        {
                           required: true,
                           message: 'Please input your Amount!',
                        },
                        {
                           validator: validatePositiveNumber,
                        },
                        ({ getFieldValue }) => ({
                           validator(_, value) {
                              if (
                                 getFieldValue('min') !== undefined &&
                                 value !== undefined &&
                                 value <= getFieldValue('min')
                              ) {
                                 return Promise.reject(
                                    new Error(
                                       'Max must be greater than to Min',
                                    ),
                                 );
                              }
                              return Promise.resolve();
                           },
                        }),
                     ]}
                  >
                     <InputNumberComponent
                        allowDecimal
                        addonBefore="ETH"
                        placeholder="Max"
                     />
                  </Form.Item>
                  <div className="flex justify-between gap-2">
                     <Button
                        className="w-full"
                        size="middle"
                        icon={<SearchOutlined />}
                        type="primary"
                        htmlType="submit"
                     >
                        Search
                     </Button>
                     <Button
                        className="w-full"
                        size="middle"
                        onClick={() => {
                           clearFilters && clearFilters();
                           confirm();
                           setSelectedKeys([]);
                           formETH.resetFields();
                           updateSearchParams({
                              type: dataIndex,
                              value: undefined,
                           });
                        }}
                     >
                        Reset
                     </Button>
                  </div>
               </Form>
            </div>
         </div>
      ),
      filterIcon: FilterIconRender(searchParams, dataIndex, 'range'),
      onFilterDropdownOpenChange: (visible) => {
         if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
         }
      },
      render: (text) => text,
   });

   const getColumnSearchTokenRangeProps = (
      dataIndex: DataIndex,
   ): TableColumnType<DataTypeUserTx> => ({
      filterDropdown: ({
         setSelectedKeys,
         // selectedKeys,
         confirm,
         clearFilters,
         // close,
      }) => (
         <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <div style={{ padding: 8, width: 300 }}>
               <Form
                  form={formToken}
                  size="middle"
                  onFinish={(values) => {
                     confirm();
                     setSelectedKeys(values);
                     updateSearchParams({
                        type: dataIndex,
                        value: values,
                     });
                  }}
               >
                  <Form.Item
                     name="min"
                     rules={[
                        {
                           required: true,
                           message: 'Please input your Amount!',
                        },
                        {
                           validator: validatePositiveNumber,
                        },
                     ]}
                  >
                     <InputNumberComponent
                        allowDecimal
                        addonBefore={project?.name.toLocaleUpperCase()}
                        placeholder="Min"
                     />
                  </Form.Item>
                  <Form.Item
                     name="max"
                     rules={[
                        {
                           required: true,
                           message: 'Please input your Amount!',
                        },
                        {
                           validator: validatePositiveNumber,
                        },
                        ({ getFieldValue }) => ({
                           validator(_, value) {
                              if (
                                 getFieldValue('min') !== undefined &&
                                 value !== undefined &&
                                 value <= getFieldValue('min')
                              ) {
                                 return Promise.reject(
                                    new Error(
                                       'Max must be greater than to Min',
                                    ),
                                 );
                              }
                              return Promise.resolve();
                           },
                        }),
                     ]}
                  >
                     <InputNumberComponent
                        allowDecimal
                        addonBefore={project?.name.toLocaleUpperCase()}
                        placeholder="Max"
                     />
                  </Form.Item>
                  <div className="flex justify-between gap-2">
                     <Button
                        className="w-full"
                        size="middle"
                        icon={<SearchOutlined />}
                        type="primary"
                        htmlType="submit"
                     >
                        Search
                     </Button>
                     <Button
                        className="w-full"
                        size="middle"
                        onClick={() => {
                           clearFilters && clearFilters();
                           confirm();
                           setSelectedKeys([]);
                           formToken.resetFields();
                           updateSearchParams({
                              type: dataIndex,
                              value: undefined,
                           });
                        }}
                     >
                        Reset
                     </Button>
                  </div>
               </Form>
            </div>
         </div>
      ),
      filterIcon: FilterIconRender(searchParams, dataIndex, 'range'),
      onFilterDropdownOpenChange: (visible) => {
         if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
         }
      },
      render: (text) => text,
   });

   const columnsUserTx: TableProps<any>['columns'] = [
      {
         title: 'Date',
         dataIndex: 'createdAt',
         key: 'createdAt',
         ...sortColumns('createdAt'),
         render: (value: number) => dateConverter(value),
      },
      {
         title: 'Type',
         dataIndex: 'side',
         key: 'side',
         ...getColumnSearchMultiProps('side'),
         render: (value: TransactionType) => TransactionTypesRender(value),
      },
      {
         title: 'USD',
         dataIndex: 'amountUSDC',
         key: 'amountUSDC',
         // ...sortColumns('amountUSDC'),
         // ...getColumnSearchAnimalSingleProps('amountUSDC'),
         render: (_, record) =>
            amountWithIconRender(Number(record.amountUSDC), 'USD'),
      },
      {
         title: 'Yes',
         dataIndex: 'yesToken',
         key: 'yesToken',
         // ...sortColumns('yesToken'),
         // ...getColumnSearchETHRangeProps('yesToken'),
         render: (value: number) => formatCurrency(value, 'TOKEN'),
      },
      {
         title: 'No',
         dataIndex: 'noToken',
         key: 'noToken',
         // ...sortColumns('noToken'),
         // ...getColumnSearchTokenRangeProps('noToken'),
         render: (value: number) => formatCurrency(value, 'TOKEN'),
      },
      {
         title: 'Maker',
         dataIndex: 'maker',
         key: 'maker',
         ...getColumnSearchTextProps('maker'),
         render: (text) => <AddressCopyableComponent text={text} />,
      },
      // {
      //    title: 'Txn',
      //    dataIndex: 'hash',
      //    align: 'center',
      //    key: 'hash',
      //    render: (_, record) => (
      //       <a
      //          className="flex justify-center"
      //          href={transactionDexscreenerScan(
      //             project?.pairAddress || '',
      //             record?.sourceAddress,
      //             project ? project.chainId : 1,
      //          )}
      //          target="_blank"
      //          rel="noopener, noreferrer"
      //       >
      //          <OpenSvg />
      //       </a>
      //    ),
      // },
   ];

   return (
      <>
         {/* <Highlighter
            searchWords={['dog', 'cat']}
            textToHighlight="The dog is chasing the cat. Or perhaps they're just playing?"
         /> */}
         {isTrigger && (
            <div className="fixed z-50 bottom-0 left-0 right-0  pointer-events-none">
               <img
                  className="w-full h-[100vh]"
                  src={`/images/gif/gif-whale-2.gif?${new Date().getTime()}`}
                  alt="hehe"
               />
            </div>
         )}
         <Title level={3}>Transactions</Title>
         <Table
            className="my-6"
            loading={fetchingUserTX}
            columns={columnsUserTx}
            dataSource={dataUserTx}
            pagination={paginationConfig}
            bordered
            onChange={handleTableChange}
            rowClassName={() => 'cursor-pointer'}
            scroll={{ x: 'max-content' }}
         />
      </>
   );
};

export default StrateryUserTransactionComponent;
