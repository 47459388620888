import React from 'react';

const LauchSvg: React.FC = () => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 128 128"
      width="20"
      height="20"
   >
      <polygon
         points="3.77,71.73 20.11,55.63 47.93,50.7 45.18,65.26 7.57,76.82 5.14,75.77 "
         style={{ fill: 'rgb(202, 44, 49)' }}
      ></polygon>
      <polygon
         points="22.94,59.76 5.2,75.88 18.25,82.24 38.06,72.13 38.06,67.36 42.11,56.44 "
         style={{ fill: 'rgb(160, 36, 34)' }}
      ></polygon>
      <path
         d="M64.92,88.15l-8.57,3.72l-8.09,17.15c0,0,7.12,15.77,7.44,15.77c0.32,0,4.37,0.32,4.37,0.32 l14.4-16.1l3.64-27.5L64.92,88.15z"
         style={{ fill: 'rgb(160, 36, 34)' }}
      ></path>
      <path
         d="M56.5,100.84c0,0,4.77-0.97,8.17-2.59c3.4-1.62,7.6-4.04,7.6-4.04l-1.54,13.43l-15.05,17.13 c0,0-0.59-0.73-3.09-6.17c-1.99-4.34-2.68-5.89-2.68-5.89L56.5,100.84z"
         style={{ fill: 'rgb(202, 44, 49)' }}
      ></path>
      <path
         d="M31.58,80.66c0,0-5.74-0.48-12.03,7.47c-5.74,7.26-8.43,19.08-9.47,22.12s-3.53,3.66-2.7,5.05 s4.42,1.31,8.85,0.76s8.23-1.94,8.23-1.94s-0.19,0.48-0.83,1.52c-0.23,0.37-1.03,0.9-0.97,1.45c0.14,1.31,11.36,1.34,20.32-7.88 c9.68-9.95,4.98-18.11,4.98-18.11L31.58,80.66z"
         style={{ fill: 'rgb(247, 215, 77)' }}
      ></path>
      <path
         d="M33.31,85.29c0,0-6.19,0.33-11.31,8.28s-7.5,17.16-7.01,17.78c0.48,0.62,10.02-2.83,12.31-2.14 c1.57,0.48,0.76,2.07,1.18,2.49c0.35,0.35,4.49,0.94,11.19-6.32c6.71-7.26,5.12-17.46,5.12-17.46L33.31,85.29z"
         style={{ fill: 'rgb(251, 240, 180)' }}
      ></path>
      <path
         d="M36.35,74.44c0,0-3.11,2.77-4.22,4.36c-1.11,1.59-1.11,1.73-1.04,2.21 c0.07,0.48,1.22,5.75,6.01,10.37c5.88,5.67,11.13,6.43,11.89,6.43c0.76,0,5.81-5.67,5.81-5.67L36.35,74.44z"
         style={{ fill: 'rgb(133, 133, 133)' }}
      ></path>
      <path
         d="M50.1,91.24c0,0,5.04,3.31,13.49,0.47c11.55-3.88,20.02-12.56,30.51-23.52 c10.12-10.58,18.61-23.71,18.61-23.71l-5.95-19.93L50.1,91.24z"
         style={{ fill: 'rgb(67, 118, 135)' }}
      ></path>
      <path
         d="M67.99,80.33l1.39-4.32l3.48,0.49c0,0,2.65,1.25,4.6,2.16c1.95,0.91,4.46,1.6,4.46,1.6l-4.95,4.18 c0,0-2.7-1.02-4.67-1.88C70.08,81.59,67.99,80.33,67.99,80.33z"
         style={{ fill: 'rgb(63, 84, 95)' }}
      ></path>
      <path
         d="M84.32,16.14c0,0-9.62,5.58-23.41,18.63c-12.43,11.76-21.64,22.4-23.87,31.45 c-1.86,7.58-0.87,12.18,3.36,17.15c4.47,5.26,9.71,7.87,9.71,7.87s3.94,0.06,20.38-12.59c20.51-15.79,36.94-42.23,36.94-42.23 L84.32,16.14z"
         style={{ fill: 'rgb(141, 175, 191)' }}
      ></path>
      <path
         d="M104.18,41.84c0,0-8.37-3.57-14.34-11.9c-5.93-8.27-5.46-13.86-5.46-13.86s4.96-3.89,16.11-8.34 c7.5-2.99,17.71-4.52,21.07-2.03s-2.3,14.98-2.3,14.98l-10.31,19.96L104.18,41.84z"
         style={{ fill: 'rgb(216, 63, 34)' }}
      ></path>
      <path
         d="M68.17,80.4c0,0-7.23-3.69-11.83-8.94c-8.7-9.91-10.5-20.79-10.5-20.79l4.37-5.13 c0,0,1.09,11.56,10.42,21.55c6.08,6.51,12.43,9.49,12.43,9.49s-1.27,1.07-2.63,2.11C69.56,79.36,68.17,80.4,68.17,80.4z"
         style={{ fill: 'rgb(104, 150, 165)' }}
      ></path>
      <path
         d="M112.71,44.48c0,0,4.34-5.23,8.45-17.02c5.74-16.44,0.74-21.42,0.74-21.42s-1.69,7.82-7.56,18.69 c-4.71,8.71-10.41,17-10.41,17s3.14,1.41,4.84,1.9C110.91,44.25,112.71,44.48,112.71,44.48z"
         style={{ fill: 'rgb(160, 36, 34)' }}
      ></path>
      <path
         d="M39.81,69.66c1.3,1.24,3.27-0.39,3.27-0.39l1.35-4.36c0,0-1.68-1.37-3.47-0.82c-1.8,0.55-2.95,2.88-2.95,2.88 L39.81,69.66z"
         style={{ fill: 'rgb(247, 215, 77)' }}
      ></path>
      <path
         d="M57.48,89.96c0,0-3.67,3.67-8.6,2.06c-4.93-1.6-6.7-7.83-6.7-7.83s5.48-1.04,7.62,1.41 c1.71,2.01,2.5,3.89,2.5,3.89L57.48,89.96z"
         style={{ fill: 'rgb(202, 44, 49)' }}
      ></path>
      <path
         d="M36.09,76.67c0,0-3.18,3.49-6.52,6.29c-3.34,2.81-4.93,5.66-4.93,5.66l-0.7-4.8 c0,0,1.84-3.14,4.6-5.84C30.85,77.09,35.32,73.55,36.09,76.67z"
         style={{ fill: 'rgb(160, 36, 34)' }}
      ></path>
      <path
         d="M42.62,73.18c0,0-1.83,1.75-4.11,3.95c-2.29,2.21-3.37,4.46-3.37,4.46l-1.24-2.41 c0,0,1.46-2.28,3.26-3.85C39.3,73.83,42.62,71.84,42.62,73.18z"
         style={{ fill: 'rgb(247, 215, 77)' }}
      ></path>
      <path
         d="M49.48,90.11c0,0-2.48,1.87-5.21,1.64c-2.74-0.23-3.95-2.55-3.95-2.55s2.2-1.1,4.1-0.63 c1.9,0.47,3.47,1.73,3.47,1.73L49.48,90.11z"
         style={{ fill: 'rgb(202, 44, 49)' }}
      ></path>
      <path
         d="M46.61,84.2c0,0-2.08,1.49-4.22,1.07c-2.14-0.41-3.03-1.97-3.03-1.97s1.62-0.68,3.07-0.5 c1.45,0.18,2.86,1.01,2.86,1.01L46.61,84.2z"
         style={{ fill: 'rgb(160, 36, 34)' }}
      ></path>
      <path
         d="M44.83,79.17c0,0-1.76,1.08-3.41,0.59c-1.66-0.48-2.37-1.69-2.37-1.69s1.26-0.47,2.48-0.3 c1.22,0.18,2.38,0.68,2.38,0.68L44.83,79.17z"
         style={{ fill: 'rgb(247, 215, 77)' }}
      ></path>
      <path
         d="M58.53,80.21c0,0-1.75,1.3-3.9,0.73c-2.15-0.57-3.08-2.14-3.08-2.14s1.63-0.89,3.1-0.64 c1.48,0.25,2.89,0.94,2.89,0.94L58.53,80.21z"
         style={{ fill: 'rgb(247, 215, 77)' }}
      ></path>
   </svg>
);

export default LauchSvg;
