import React from 'react';

const DoneSvg: React.FC = () => {
   return (
      <svg
         xmlns="http://www.w3.org/2000/svg"
         version="1.1"
         width="20"
         height="20"
         viewBox="0 0 256 256"
      >
         <defs></defs>
         <g
            style={{
               stroke: 'none',
               strokeWidth: 0,
               strokeDasharray: 'none',
               strokeLinecap: 'butt',
               strokeLinejoin: 'miter',
               strokeMiterlimit: 10,
               fill: 'none',
               fillRule: 'nonzero',
               opacity: 1,
            }}
            transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
         >
            <path
               d="M 78.456 90 H 11.544 C 5.168 90 0 84.832 0 78.456 V 11.544 C 0 5.168 5.168 0 11.544 0 h 66.912 C 84.832 0 90 5.168 90 11.544 v 66.912 C 90 84.832 84.832 90 78.456 90 z"
               style={{
                  stroke: 'none',
                  strokeWidth: 1,
                  strokeDasharray: 'none',
                  strokeLinecap: 'butt',
                  strokeLinejoin: 'miter',
                  strokeMiterlimit: 10,
                  fill: 'rgb(50,249,110)',
                  fillRule: 'nonzero',
                  opacity: 1,
               }}
               transform="matrix(1 0 0 1 0 0)"
            />
            <polygon
               points="37.16,70.51 14.54,47.9 23.03,39.41 37.16,53.54 66.97,23.73 75.46,32.22"
               style={{
                  stroke: 'none',
                  strokeWidth: 1,
                  strokeDasharray: 'none',
                  strokeLinecap: 'butt',
                  strokeLinejoin: 'miter',
                  strokeMiterlimit: 10,
                  fill: 'rgb(255,255,255)',
                  fillRule: 'nonzero',
                  opacity: 1,
               }}
               transform="matrix(1 0 0 1 0 0)"
            />
         </g>
      </svg>
   );
};

export default DoneSvg;
