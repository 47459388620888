import React, { useState } from 'react';
import ProjectPnLComponent from './ProjectPnLComponent';
import ProjectCalculateInWalletComponent from './ProjectCalculateInWalletComponent';
import ProjectUserTransactionComponent from './ProjectUserTransactionComponent';
import usePermissions from '../../../hooks/usePermissions';
import { PermissionName } from '../../../constants/permissions';
import ProjectTopBuyerComponent from './ProjectTopBuyerComponent';
import CustomSegmentedComponent from '../../../components/CustomSegmentedComponent';
import TopbuyerSvg from '../../../assets/svg/topbuyer';
import TransactionSvg from '../../../assets/svg/transaction';
import InWalletSvg from '../../../assets/svg/inwallet';
import OverviewSvg from '../../../assets/svg/overview';
import { Project } from '../../../redux/projects/type';

interface ProjectMonitorComponentProps {
   project: Project | null;
   projectLoading: boolean;
}

const ProjectSyncswapMonitorComponent: React.FC<
   ProjectMonitorComponentProps
> = ({ project, projectLoading }) => {
   const { hasPermission } = usePermissions();
   const [activeKey, setActiveKey] = useState('1');
   const segmentedItems = [
      {
         key: '1',
         text: 'Overview',
         icon: OverviewSvg,
         hasPermission:
            hasPermission(PermissionName.ACCESS_SYNCSWAP) &&
            hasPermission(PermissionName.MONITOR_OVERVIEW),
         component: (
            <ProjectPnLComponent
               project={project}
               projectLoading={projectLoading}
            />
         ),
      },
      {
         key: '2',
         text: 'In Wallets',
         icon: InWalletSvg,
         hasPermission:
            hasPermission(PermissionName.ACCESS_SYNCSWAP) &&
            hasPermission(PermissionName.MONITOR_INWALLET),
         component: (
            <ProjectCalculateInWalletComponent
               project={project}
               projectLoading={projectLoading}
            />
         ),
      },
      {
         key: '3',
         text: 'Transactions',
         icon: TransactionSvg,
         hasPermission:
            hasPermission(PermissionName.ACCESS_SYNCSWAP) &&
            hasPermission(PermissionName.MONITOR_USERTRANSACTION),
         component: <ProjectUserTransactionComponent />,
      },
      {
         key: '4',
         text: 'Top Buyers',
         icon: TopbuyerSvg,
         hasPermission:
            hasPermission(PermissionName.ACCESS_SYNCSWAP) &&
            hasPermission(PermissionName.MONITOR_TOPBUYER),
         component: <ProjectTopBuyerComponent />,
      },
   ];

   return (
      <div className="projects-monitor-component pb-8">
         <CustomSegmentedComponent
            items={segmentedItems}
            activeKey={activeKey}
            setActiveKey={setActiveKey}
            defaultActiveKey="1"
         />
         {/* <ProjectLockSellComponent /> */}
      </div>
   );
};

export default ProjectSyncswapMonitorComponent;
