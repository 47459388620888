export enum RoleName {
   SUPER_ADMIN = 'superadmin',
   INTERNAL_ADMIN = 'internal_admin',
   INTERNAL_MODERATOR = 'internal_moderator',
   EXTERNAL_ADMIN = 'external_admin',
   EXTERNAL_MODERATOR = 'external_moderator',
   VIEWER_ZKSYNC = 'viewer_zksync',
}

export const INTERNAL_ROLES = [
   {
      key: RoleName.SUPER_ADMIN,
      label: 'Aquafox Super Admin',
   },
   {
      key: RoleName.INTERNAL_ADMIN,
      label: 'Aquafox Admin',
   },
   {
      key: RoleName.INTERNAL_MODERATOR,
      label: 'Aquafox Moderator',
   },
];

export const CAN_CREATE_EDIT_INTERNAL_ROLES = [
   {
      key: RoleName.INTERNAL_ADMIN,
      label: 'Admin',
   },
   {
      key: RoleName.INTERNAL_MODERATOR,
      label: 'Moderator',
   },
];

export const EXTERNAL_ROLES = [
   {
      key: RoleName.EXTERNAL_ADMIN,
      label: 'Partner Admin',
   },
   {
      key: RoleName.EXTERNAL_MODERATOR,
      label: 'Partner Moderator',
   },
];

export const CAN_CREATE_EDIT_EXTERNAL_ROLES = [
   {
      key: RoleName.EXTERNAL_ADMIN,
      label: 'Admin',
   },
   {
      key: RoleName.EXTERNAL_MODERATOR,
      label: 'Moderator',
   },
];

export const MapRoleToName = [
   {
      key: RoleName.SUPER_ADMIN,
      label: 'Aquafox SuperAdmin',
   },
   {
      key: RoleName.INTERNAL_ADMIN,
      label: 'Aquafox Admin',
   },
   {
      key: RoleName.INTERNAL_MODERATOR,
      label: 'Aquafox Moderator',
   },
   {
      key: RoleName.EXTERNAL_ADMIN,
      label: 'Partner Admin',
   },
   {
      key: RoleName.EXTERNAL_MODERATOR,
      label: 'Partner Moderator',
   },
   {
      key: RoleName.VIEWER_ZKSYNC,
      label: 'Viewer Zksync',
   },
];

export const MapRoleToNameRemoveCompany = [
   {
      key: RoleName.SUPER_ADMIN,
      label: 'SuperAdmin',
   },
   {
      key: RoleName.INTERNAL_ADMIN,
      label: 'Admin',
   },
   {
      key: RoleName.INTERNAL_MODERATOR,
      label: 'Moderator',
   },
   {
      key: RoleName.EXTERNAL_ADMIN,
      label: 'Admin',
   },
   {
      key: RoleName.EXTERNAL_MODERATOR,
      label: 'Moderator',
   },
   {
      key: RoleName.VIEWER_ZKSYNC,
      label: 'Viewer Zksync',
   },
];

export enum PermissionName {
   // project role
   PROJECT_BYCOMPANY = 'project.bycompany',
   PROJECT_VIEW = 'project.view',
   PROJECT_CREATE = 'project.create',
   PROJECT_MODIFY = 'project.modify',
   PROJECT_ASSIGN = 'project.assign',

   // monitor
   MONITOR_OVERVIEW = 'monitor.overview',
   MONITOR_USERTRANSACTION = 'monitor.usertransaction',
   MONITOR_TOPBUYER = 'monitor.topbuyer',
   MONITOR_INWALLET = 'monitor.inwallet',

   // launch
   LAUNCH_VIEW = 'launch.view',
   LAUNCH_CHANGE_CONFIG = 'launch.changeconfig',

   // boost
   BOOST_VIEW = 'boost.view',
   BOOST_CHANGECONFIG = 'boost.changeconfig',
   BOOST_RUNPAUSE = 'boost.runpause',

   // take profit
   TAKEPROFIT_VIEW = 'takeprofit.view',
   TAKEPROFIT_ACTION = 'takeprofit.action',

   // MM GENERATOR
   MMLIST_VIEW = 'mmlist.view',
   MMLIST_ACTION = 'mmlist.action',
   MMLIST_CREATE = 'mmlist.create',
   MMGENERATOR_VIEW = 'mmgenerator.view',
   MMGENERATOR_MODIFY = 'mmgenerator.modify',
   MMGENERATOR_FILE = 'mmgenerator.file',
   MMGENERATOR_SNIPE = 'mmgenerator.snipe',
   MMGENERATOR_BUDGET = 'mmgenerator.budget',

   // THIRD PARTY
   ACCESS_PREDICTHUB = 'access.predicthub',
   ACCESS_UNISWAP = 'access.uniswap',
   ACCESS_SYNCSWAP = 'access.syncswap',

   // ACCOUNT
   ACCOUNT_VIEW = 'account.view',
   ACCOUNT_CREATE = 'account.create',
   ACCOUNT_MODIFY = 'account.modify',
   ACCOUNT_DELETE = 'account.delete',

   // COMPANY
   COMPANY_CREATE = 'company.create',
   COMPANY_VIEW = 'company.view',

   // FUND MANAGE
   FUNDMANAGE_VIEW = 'fundmanage.view',
}
