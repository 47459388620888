import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from '../../../routes/PrivateRoute';
import { Skeleton, Result } from 'antd';
import usePermissions from '../../../hooks/usePermissions';
import { PermissionName } from '../../../constants/permissions';

// const ChartGenerator2Component = lazy(
//    () => import('../ChartGenerator2Component'),
// );

const CompanyAccountListComponent = lazy(() => 
  import('../../Accounts/CompanyAccountListComponent').catch(() => ({
    default: () => (
      <Result
        status="error"
        title="Failed to load component"
        subTitle="Please try refreshing the page"
      />
    )
  }))
);

const CompanyAccountRouterComponent = () => {
   const { hasPermission } = usePermissions();

   return (
      <Suspense
         fallback={
            <Skeleton
               className="container py-5 mx-auto h-full"
               paragraph={{ rows: 6 }}
               active
            />
         }
      >
         <Routes>
            <Route
               path="/"
               element={
                  <PrivateRoute
                     hasPermission={hasPermission(PermissionName.ACCOUNT_VIEW)}
                     element={<CompanyAccountListComponent />}
                  />
               }
            />

         </Routes>
      </Suspense>
   );
};

export default CompanyAccountRouterComponent;
