/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState} from 'react';
import { Card, message, Table, TableProps } from 'antd';
import Title from 'antd/es/typography/Title';
import { ERROR_MESSAGE } from '../../../../constants/common';
import { getDataBot } from '../../../../services/strategyServices';
import { convertNumberToSeparatedString } from '../../../../utils/convertNumberToSeparatedString';

const columns: TableProps<any>['columns'] = [
   {
      title: 'Bot',
      dataIndex: 'name',
      key: 'name',
   },
   {
      title: 'USDC',
      dataIndex: 'usdc',
      key: 'usdc',
   },
   {
      title: 'Yes',
      dataIndex: 'yes',
      key: 'yes',
   },
   {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
   },
];
interface StrategyMonitorBotProps {
   id?: string;
 }

const StrategyMonitorBot: React.FC<StrategyMonitorBotProps> = ({ id }) => {
 
   const [loading, setLoading] = useState(false);
   const [data, setData] = useState([])

   useEffect(() => {
      const fetchOverview = async () => {
         try {
            setLoading(true);
            const res = await getDataBot(id);
            if(res?.data?.data) {
               setData((res?.data?.data || []).map((item: any) => ({
                  name: item.botType,
                  usdc: convertNumberToSeparatedString(item.pnlUsdc),
                  yes: convertNumberToSeparatedString(item.yesTokenAmount),
                  no: convertNumberToSeparatedString(item.noTokenAmount),
               })))
            }
            setLoading(false);
         } catch (error) {
            setLoading(false);
            message.error(ERROR_MESSAGE);
         }
      };
      fetchOverview();
   }, []);

   return (
      <div className="projects-monitor-component mt-8">
         <Card>
            <div className="flex justify-between items-center">
               <Title className="!mb-3" level={3}>
                  Monitor Bot
               </Title>

            </div>
            <Table
               columns={columns}
               dataSource={data}
               loading={loading}
               bordered
               pagination={false}
               scroll={{ x: 'max-content' }}
            />
         </Card>
      </div>
   );
};

export default StrategyMonitorBot;
