import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from '../../../routes/PrivateRoute';
import { Skeleton } from 'antd';
import usePermissions from '../../../hooks/usePermissions';
import { PermissionName } from '../../../constants/permissions';
import ProjectSyncswapOverviewComponent from '../ProjectSyncswapMonitor/ProjectSyncswapOverviewComponent';

const ProjectOverviewComponent = lazy(
   () => import('../ProjectMonitor/ProjectOverviewComponent'),
);

const ProjectsMainComponent = lazy(() => import('../ProjectsMainComponent'));

const ProjectsSyncswapRouterComponent = () => {
   const { hasPermission } = usePermissions();

   return (
      <Suspense
         fallback={
            <Skeleton
               className="container py-5 mx-auto h-full"
               paragraph={{ rows: 6 }}
               active
            />
         }
      >
         <div className="container py-5 mx-auto h-full">
            <Routes>
               <Route
                  path="/"
                  element={<PrivateRoute element={<ProjectsMainComponent />} />}
               />
               <Route
                  path=":id/monitor"
                  element={
                     <PrivateRoute
                        hasPermission={
                           hasPermission(PermissionName.MONITOR_OVERVIEW) &&
                           hasPermission(PermissionName.ACCESS_SYNCSWAP)
                        }
                        element={<ProjectSyncswapOverviewComponent />}
                     />
                  }
               />
               <Route
                  path="*"
                  element={<PrivateRoute element={<ProjectsMainComponent />} />}
               />
               <Route
                  path=":id"
                  element={
                     <PrivateRoute
                        hasPermission={
                           (hasPermission(PermissionName.MONITOR_OVERVIEW) ||
                              hasPermission(PermissionName.LAUNCH_VIEW) ||
                              hasPermission(PermissionName.BOOST_VIEW)) &&
                           hasPermission(PermissionName.ACCESS_SYNCSWAP)
                        }
                        element={<ProjectOverviewComponent />}
                     />
                  }
               />
            </Routes>
         </div>
      </Suspense>
   );
};

export default ProjectsSyncswapRouterComponent;
