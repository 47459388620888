/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Flex, message, Modal, Table, TableProps } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import React, { useEffect, useState } from 'react';
import {
   DEFAULT_MM_PROJECT_ID,
   DEFAULT_PAGINATE_SIZE,
   ERROR_MESSAGE,
} from '../../constants/common';
import {
   deleteDataMMB,
   duplicateDataMMB,
   getMMs,
} from '../../services/mmGeneratorServices';
import useSortOrder from '../../hooks/useSortOrder';
import dateTimeUTCConverter from '../../utils/dateTimeUTCConverter';
import BreadcrumbComponent from '../../components/BreadcrumbComponent';
import { ExclamationCircleTwoTone, PlusOutlined } from '@ant-design/icons';
import { SorterResult } from 'antd/es/table/interface';
import { useNavigate, useParams } from 'react-router-dom';
import usePaginationConfig from '../../hooks/usePaginationConfig';
import Title from 'antd/es/typography/Title';
import Delete from '../../assets/svg/delete';
import Copy from '../../assets/svg/copy';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../redux/store';
import { fetchProjectById } from '../../redux/projects/projectsSlice';
import LoadingComponent from '../../components/LoadingComponent';
import Paragraph from 'antd/es/typography/Paragraph';
import { fetchCompanyById } from '../../redux/companies/companiesSlice';
import BigNumber from 'bignumber.js';

interface Params {
   id: string;
}

const ProjectsListComponent = () => {
   const [fetchingMMs, setFetchingMMs] = useState<boolean>(false);
   const params = useParams();
   const [page, setPage] = useState<number>(1);
   const [total, setTotal] = useState<number>(0);
   const { sortOrder, sortColumns, updateSortOrder, getSortParams } =
      useSortOrder<any>();
   const [data, setData] = useState<any[]>([]);
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const paginationConfig = usePaginationConfig(total, page, setPage);
   const [actionState, setActionState] = useState<any>({
      isOpen: false,
      text: '',
      isLoading: false,
   });

   const { project, loading: projectLoading } = useSelector(
      (state: RootState) => state.projects,
   );

   const { company, loading: companyLoading } = useSelector(
      (state: RootState) => state.companies,
   );

   const isDefaultProject = (id: string) => {
      const projID = new BigNumber(id);
      return projID.eq(DEFAULT_MM_PROJECT_ID);
   };

   useEffect(() => {
      if (
         !project &&
         !projectLoading &&
         params?.id &&
         isDefaultProject(params?.id)
      ) {
         dispatch(fetchProjectById(+params?.id, navigate));
      }
   }, [dispatch, project, projectLoading]);

   useEffect(() => {
      if (
         !company &&
         project?.company?.id &&
         params?.id &&
         isDefaultProject(params?.id)
      ) {
         dispatch(fetchCompanyById(+project?.company?.id, navigate));
      }
   }, [dispatch, project?.company?.id]);

   const mmBreadcrumbs = [
      {
         title: 'Project Management',
         link: '/company/projects',
      },
      ...((params as unknown as Params).id === '0'
         ? []
         : [
              {
                 title: (
                    <LoadingComponent size={20} loading={companyLoading}>
                       {company?.name}
                    </LoadingComponent>
                 ),
                 link: `/company/${project?.company?.id}/project/`,
              },
           ]),
      ...(project?.tokenAddress
         ? [
              {
                 title: (
                    <LoadingComponent size={20} loading={projectLoading}>
                       <Paragraph
                          className="!m-0 flex items-center text-base"
                          copyable={{
                             tooltips: ['Copy Address', 'Copied'],
                             text: project?.tokenAddress,
                          }}
                       >
                          {project?.name}
                       </Paragraph>
                    </LoadingComponent>
                 ),
                 link: `/company/${project?.company?.id}/project/${project?.id}/monitor`,
              },
           ]
         : []),
      {
         title: 'MM Data storage',
      },
   ];

   const fetchProject = async () => {
      try {
         setFetchingMMs(true);
         const res = await getMMs({
            page,
            limit: DEFAULT_PAGINATE_SIZE,
            orderBy: 'updatedAt',
            sortBy: 'desc',
            projectId: params?.id,
            ...getSortParams(),
         });
         const dataMMs = res?.data?.data;
         setData(dataMMs?.items);
         setTotal(dataMMs?.meta?.total);
         setFetchingMMs(false);
      } catch (error) {
         setFetchingMMs(false);
         message.error(ERROR_MESSAGE);
      }
   };

   useEffect(() => {
      fetchProject();
   }, [page, sortOrder]);

   const handleDuplicate = async (id: string) => {
      try {
         setActionState((prevState: any) => ({
            ...prevState,
            isLoading: true,
         }));
         await duplicateDataMMB(id);
         message.success('Duplicate successfully!');
         setActionState({
            isOpen: false,
            text: '',
            isLoading: false,
         });
         fetchProject();
      } catch (err) {
         setActionState((prevState: any) => ({
            ...prevState,
            isLoading: false,
         }));
         message.error(ERROR_MESSAGE);
      }
   };

   const handleDelete = async (id: string) => {
      try {
         setActionState((prevState: any) => ({
            ...prevState,
            isLoading: true,
         }));
         await deleteDataMMB(id);
         message.success('Delete successfully!');
         setActionState({
            isOpen: false,
            text: '',
            isLoading: false,
         });
         fetchProject();
      } catch (err) {
         setActionState((prevState: any) => ({
            ...prevState,
            isLoading: false,
         }));
         message.error(ERROR_MESSAGE);
      }
   };

   const columns: TableProps<any>['columns'] = [
      {
         title: 'MM Name',
         dataIndex: 'name',
         key: 'name',
         // align: 'center',
      },
      {
         title: 'Saved',
         dataIndex: 'updatedAt',
         key: 'updatedAt',
         // align: 'center',
         ...sortColumns('updatedAt'),
         render: (value: string) => dateTimeUTCConverter(value),
         width: 150,
      },
      {
         title: 'Start Date',
         dataIndex: 'stateDate',
         key: 'stateDate',
         // align: 'center',
         // ...sortColumns('stateDate'),
         render: (value: string) => dateTimeUTCConverter(value),
         width: 150,
      },
      {
         title: 'End Date',
         dataIndex: 'endDate',
         key: 'endDate',
         // align: 'center',
         // ...sortColumns('endDate'),
         render: (value: string) => dateTimeUTCConverter(value),
         width: 150,
      },
      {
         title: 'Action',
         dataIndex: 'action',
         align: 'center',
         width: 120,
         key: 'action',
         render: (_, record) => (
            <div
               className="flex gap-4 justify-center"
               onClick={(event) => {
                  event.stopPropagation();
               }}
            >
               <div
                  className="flex items-center justify-center bg-[#EB3737] w-[25px] h-[25px] rounded-[5px]"
                  onClick={() => {
                     setActionState({
                        isOpen: true,
                        text: 'delete',
                        isLoading: false,
                        id: record?.id,
                     });
                  }}
               >
                  <Delete />
               </div>
               <div
                  className="flex items-center justify-center bg-[#0CA7EE] w-[25px] h-[25px] rounded-[5px]"
                  onClick={() => {
                     setActionState({
                        isOpen: true,
                        text: 'duplicate',
                        isLoading: false,
                        id: record?.id,
                     });
                  }}
               >
                  <Copy />
               </div>
            </div>
         ),
      },
   ];

   const handleTableChange = (
      pagination: any,
      filters: any,
      sorter: SorterResult<any> | SorterResult<any>[],
   ) => {
      updateSortOrder(sorter);
   };

   return (
      <div
         className={`${params?.id && isDefaultProject(params?.id) ? 'container py-5' : ''}  mx-auto h-full`}
      >
         <div className="projects-list-component">
            {params?.id && isDefaultProject(params?.id) && (
               <BreadcrumbComponent items={mmBreadcrumbs} />
            )}
            <Flex
               justify="space-between"
               align="center"
               className="mb-[20px]"
               wrap
               gap="middle"
            >
               <Title className="leading-none !mb-0 text-dark" level={3}>
                  MM File
               </Title>
               <div className="flex gap-3 max-sm:hidden">
                  <Button
                     icon={<PlusOutlined />}
                     type="primary"
                     onClick={() =>
                        navigate(`/chart-generator/${project?.id}/create`)
                     }
                  >
                     New Chart
                  </Button>
               </div>
            </Flex>
            <Table
               columns={columns}
               dataSource={data}
               loading={fetchingMMs}
               bordered
               onChange={handleTableChange}
               pagination={paginationConfig}
               rowClassName={() => 'cursor-pointer'}
               onRow={(record) => {
                  return {
                     onClick: () => {
                        navigate(
                           `/chart-generator/${record?.projectId}/${record?.id}`,
                        );
                     },
                  };
               }}
               // scroll={{ x: 'max-content' }}
            />
         </div>
         <Modal
            title={
               <div className="flex gap-3">
                  <ExclamationCircleTwoTone style={{ fontSize: 20 }} />
                  {actionState?.text.toLocaleUpperCase()}
               </div>
            }
            open={actionState?.isOpen}
            onOk={() => {
               actionState?.text === 'duplicate'
                  ? handleDuplicate(actionState?.id)
                  : handleDelete(actionState?.id);
            }}
            confirmLoading={actionState?.isLoading}
            onCancel={() => {
               setActionState({
                  isOpen: false,
                  text: '',
                  isLoading: false,
               });
            }}
            okText="Confirm"
         >
            <p>{`Do you want to ${actionState?.text} this data?`}</p>
         </Modal>
      </div>
   );
};

export default ProjectsListComponent;
