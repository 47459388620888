import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Col, message, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import SpaceWithBackgroundComponent from '../../../../components/SpaceWithBackgroundComponent';
import OverviewInfo from '../../../../components/OverviewInfo';
import StrateryUserTransactionComponent from './StrateryUserTransactionComponent';
import { ERROR_MESSAGE } from '../../../../constants/common';
import { getDataOverview } from '../../../../services/strategyServices';
import { StatisticData } from '../../type/StrategyTypes';
import StrategyMonitorBot from './StrategyMonitorBot';

const StrategyMonitorComponent: React.FC = () => {
   const params = useParams();
   // const [overviewCurrency, setOverviewCurrency] =
   //    useState<SelectCurrency>('USD');

   const [overviewData, setOverviewData] = useState<StatisticData>();
   const [loading, setLoading] = useState(false);

   useEffect(() => {
      const fetchOverview = async () => {
         try {
            setLoading(true);
            const res = await getDataOverview(params.id ? +params.id : 0);
            setOverviewData(res?.data?.data as StatisticData);
            setLoading(false);
         } catch (error) {
            setLoading(false);
            message.error(ERROR_MESSAGE);
         }
      };
      fetchOverview();
   }, []);

   return (
      <div className="projects-monitor-component pb-8">
         <Card>
            <div className="flex justify-between items-center">
               <Title className="!mb-0" level={3}>
                  Overview
               </Title>
            </div>
            <Row className="mt-4" gutter={20}>
               <Col span={12}>
                  <SpaceWithBackgroundComponent
                     className="p-6"
                     loading={loading}
                  >
                     <Title level={4}>P&L (market price)</Title>
                     <OverviewInfo
                        amountUsd={overviewData?.pnlMarketAmount || '0'}
                     />
                  </SpaceWithBackgroundComponent>
               </Col>
               <Col span={12}>
                  <SpaceWithBackgroundComponent
                     className="p-6"
                     loading={loading}
                  >
                     <Title level={4}>P&L (HODL)</Title>
                     <OverviewInfo
                        rangePrice={[
                           overviewData?.pnlHodl.minAmount || '0',
                           overviewData?.pnlHodl.maxAmount || '0',
                        ]}
                     />
                  </SpaceWithBackgroundComponent>
               </Col>
               <Col className="pt-[25px]" span={12}>
                  <SpaceWithBackgroundComponent
                     className="p-6"
                     loading={loading}
                  >
                     <Title level={4}>Yes</Title>
                     <OverviewInfo
                        amountWithoutCurrency={
                           overviewData?.yesTokenAmount || '0'
                        }
                     />
                  </SpaceWithBackgroundComponent>
               </Col>
               <Col className="pt-[25px]" span={12}>
                  <SpaceWithBackgroundComponent
                     className="p-6"
                     loading={loading}
                  >
                     <Title level={4}>No</Title>
                     <OverviewInfo
                        amountWithoutCurrency={
                           overviewData?.noTokenAmount || '0'
                        }
                     />
                  </SpaceWithBackgroundComponent>
               </Col>
            </Row>
         </Card>
         {params?.id && <StrategyMonitorBot id={params?.id} />}
         <Card className="mt-8">
            <StrateryUserTransactionComponent />
         </Card>
      </div>
   );
};

export default StrategyMonitorComponent;
