import React, { ReactNode } from 'react';
import LoadingComponent from './LoadingComponent';

interface SpaceWithBackgroundComponentProps {
   className?: string;
   loading?: boolean;
   children: ReactNode;
   size?: number;
}

const SpaceWithBackgroundComponent: React.FC<
   SpaceWithBackgroundComponentProps
> = ({ children, className, loading = false, size = 40 }) => {
   return (
      <LoadingComponent loading={loading} size={size}>
         <div className={`bg-slate-100 w-full h-full p-4 rounded ${className}`}>
            {children}
         </div>
      </LoadingComponent>
   );
};

export default SpaceWithBackgroundComponent;
