import React, { useEffect, useRef } from 'react';
import { SyncOutlined } from '@ant-design/icons';
import SpaceWithBackgroundComponent from './SpaceWithBackgroundComponent';
import DoneSvg from '../assets/svg/done';
import FailSvg from '../assets/svg/fail';
import WarningSvg from '../assets/svg/warning';
import InputSvg from '../assets/svg/input';
import DotFlashing from '../assets/svg/dotFlashing';

const PrinterTerminalComponent = () => {
   const bottomRef = useRef<HTMLDivElement>(null);

   const scrollToBottom = () => {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
   };

   useEffect(() => {
      setTimeout(() => {
         scrollToBottom();
      }, 300);
   }, []);

   return (
      <SpaceWithBackgroundComponent className="printer-terminal-component max-h-[500px] font-semibold flex gap-3 flex-col overflow-auto">
         <div className="flex gap-2 items-center">
            Start adding liquidity.
            <DotFlashing />
         </div>
         <div />
         <div />
         <div className="flex gap-2">
            <FailSvg />
            Failed to add liquidity, network error (code: 20).
         </div>
         <div className="flex gap-2">
            <DoneSvg />
            Add liquidity successfully!!!
         </div>
         <div className="flex gap-2">
            <WarningSvg />
            Warning
         </div>

         <div className="flex gap-2">
            <InputSvg />
            Input
         </div>
         <div className="flex gap-1 items-center">
            <div>123</div>
            <DotFlashing />
         </div>
         <div className="flex gap-2">
            <SyncOutlined spin style={{ fontSize: 18 }} /> Start adding
            liquidity
         </div>
         <div className="flex gap-2">
            <WarningSvg />
            Warning
         </div>
         <div className="flex gap-2">
            <FailSvg />
            Fail
         </div>
         <div className="flex gap-2">
            <DoneSvg />
            Done
         </div>
         <div className="flex gap-2">
            <InputSvg />
            Input
         </div>
         <div className="flex gap-1 items-center">
            <div>123</div>
            <DotFlashing />
         </div>
         <div className="flex gap-2">
            <SyncOutlined spin style={{ fontSize: 18 }} /> Start adding
            liquidity
         </div>
         <div className="flex gap-2">
            <WarningSvg />
            Warning
         </div>
         <div className="flex gap-2">
            <FailSvg />
            Fail
         </div>
         <div className="flex gap-2">
            <DoneSvg />
            Done
         </div>
         <div className="flex gap-2">
            <InputSvg />
            Input
         </div>
         <div className="flex gap-1 items-center">
            <div>123</div>
            <DotFlashing />
         </div>
         <div className="flex gap-2">
            <SyncOutlined spin style={{ fontSize: 18 }} /> Start adding
            liquidity
         </div>
         <div className="flex gap-2">
            <WarningSvg />
            Warning
         </div>
         <div className="flex gap-2">
            <FailSvg />
            Fail
         </div>
         <div className="flex gap-2">
            <DoneSvg />
            Done
         </div>
         <div className="flex gap-2">
            <InputSvg />
            Input
         </div>
         <div className="flex gap-1 items-center">
            <div>123</div>
            <DotFlashing />
         </div>
         <div className="flex gap-2">
            <SyncOutlined spin style={{ fontSize: 18 }} /> Start adding
            liquidity
         </div>
         <div className="flex gap-2">
            <WarningSvg />
            Warning
         </div>
         <div className="flex gap-2">
            <FailSvg />
            Fail
         </div>
         <div className="flex gap-2">
            <DoneSvg />
            Done
         </div>
         <div className="flex gap-2">
            <InputSvg />
            Input
         </div>
         <div className="flex gap-1 items-center">
            <div>123</div>
            <DotFlashing />
         </div>
         <div className="flex gap-2">
            <SyncOutlined spin style={{ fontSize: 18 }} /> Start adding
            liquidity
         </div>
         <div className="flex gap-2">
            <WarningSvg />
            Warning
         </div>
         <div className="flex gap-2">
            <FailSvg />
            Fail
         </div>
         <div className="flex gap-2">
            <DoneSvg />
            Done
         </div>
         <div className="flex gap-2">
            <InputSvg />
            Input
         </div>
         <div className="flex gap-1 items-center">
            <div>123</div>
            <DotFlashing />
         </div>
         <div className="flex gap-2">
            <SyncOutlined spin style={{ fontSize: 18 }} /> Start adding
            liquidity
         </div>
         <div className="flex gap-2">
            <WarningSvg />
            Warning
         </div>
         <div className="flex gap-2">
            <FailSvg />
            Fail
         </div>
         <div className="flex gap-2">
            <DoneSvg />
            Done
         </div>
         <div className="flex gap-2">
            <InputSvg />
            Input
         </div>
         <div className="flex gap-1 items-center">
            <div>123</div>
            <DotFlashing />
         </div>
         <div ref={bottomRef} />
      </SpaceWithBackgroundComponent>
   );
};

export default PrinterTerminalComponent;
