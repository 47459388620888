import React, { Suspense, lazy } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import PrivateRoute from '../../../routes/PrivateRoute';
import { Skeleton } from 'antd';
import usePermissions from '../../../hooks/usePermissions';
import { PermissionName } from '../../../constants/permissions';
import { DEFAULT_MM_PROJECT_ID } from '../../../constants/common';

const ChartGenerator2Component = lazy(
   () => import('../ChartGenerator2Component'),
);

const ChartGeneratorListComponent = lazy(
   () => import('../ChartGeneratorListComponent'),
);

const RouteWithIdCheck = ({ element }: { element: React.ReactElement }) => {
   const { id } = useParams();
   if (id !== DEFAULT_MM_PROJECT_ID) {
      return <Navigate to="/" replace />;
   }
   return element;
};

const ChartGeneratorRouterComponent = () => {
   const { hasPermission } = usePermissions();

   return (
      <Suspense
         fallback={
            <Skeleton
               className="container py-5 mx-auto h-full"
               paragraph={{ rows: 6 }}
               active
            />
         }
      >
         <Routes>
            <Route
               path=":id"
               element={
                  <PrivateRoute
                     hasPermission={hasPermission(PermissionName.MMLIST_VIEW)}
                     element={
                        <RouteWithIdCheck
                           element={<ChartGeneratorListComponent />}
                        />
                     }
                  />
               }
            />
            <Route
               path=":id/:chartId"
               element={
                  <PrivateRoute
                     hasPermission={hasPermission(
                        PermissionName.MMGENERATOR_VIEW,
                     )}
                     element={<ChartGenerator2Component />}
                  />
               }
            />
            <Route
               path=":id/create"
               element={
                  <PrivateRoute
                     hasPermission={hasPermission(
                        PermissionName.MMGENERATOR_VIEW,
                     )}
                     element={<ChartGenerator2Component />}
                  />
               }
            />
         </Routes>
      </Suspense>
   );
};

export default ChartGeneratorRouterComponent;
