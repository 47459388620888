export enum ProjectVersion {
   VERSION_1 = 'version_1',
   VERSION_2 = 'version_2',
   VERSION_3 = 'version_3',
}

export enum ProjectSpace {
   UNISWAP = 'uniswap',
   SYNCSWAP = 'syncswap',
   PREDICTHUB = 'predicthub',
}
