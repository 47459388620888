import React from 'react';

const TransactionSvg: React.FC<{ fill: string }> = ({ fill }) => (
   <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
   >
      <path
         d="M26.53 21.4698C26.6705 21.6105 26.7493 21.8011 26.7493 21.9998C26.7493 22.1986 26.6705 22.3892 26.53 22.5298L22.53 26.5298C22.3878 26.6623 22.1998 26.7344 22.0055 26.731C21.8112 26.7276 21.6258 26.6489 21.4884 26.5115C21.351 26.3741 21.2723 26.1887 21.2688 25.9944C21.2654 25.8001 21.3375 25.612 21.47 25.4698L24.1887 22.7498H6C5.80109 22.7498 5.61032 22.6708 5.46967 22.5302C5.32902 22.3895 5.25 22.1988 5.25 21.9998C5.25 21.8009 5.32902 21.6102 5.46967 21.4695C5.61032 21.3289 5.80109 21.2498 6 21.2498H24.1887L21.47 18.5298C21.3375 18.3877 21.2654 18.1996 21.2688 18.0053C21.2723 17.811 21.351 17.6256 21.4884 17.4882C21.6258 17.3508 21.8112 17.2721 22.0055 17.2687C22.1998 17.2652 22.3878 17.3374 22.53 17.4698L26.53 21.4698ZM9.47 14.5298C9.61217 14.6623 9.80022 14.7345 9.99452 14.731C10.1888 14.7276 10.3742 14.6489 10.5116 14.5115C10.649 14.3741 10.7277 14.1887 10.7312 13.9944C10.7346 13.8001 10.6625 13.612 10.53 13.4698L7.81125 10.7498H26C26.1989 10.7498 26.3897 10.6708 26.5303 10.5302C26.671 10.3895 26.75 10.1988 26.75 9.99985C26.75 9.80093 26.671 9.61017 26.5303 9.46952C26.3897 9.32886 26.1989 9.24985 26 9.24985H7.81125L10.53 6.52985C10.6625 6.38767 10.7346 6.19963 10.7312 6.00532C10.7277 5.81102 10.649 5.62564 10.5116 5.48822C10.3742 5.35081 10.1888 5.2721 9.99452 5.26867C9.80022 5.26524 9.61217 5.33737 9.47 5.46985L5.47 9.46985C5.32955 9.61047 5.25066 9.8011 5.25066 9.99985C5.25066 10.1986 5.32955 10.3892 5.47 10.5298L9.47 14.5298Z"
         fill={fill}
      />
   </svg>
);

export default TransactionSvg;
