import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from '../../../routes/PrivateRoute';
import { Skeleton } from 'antd';
import { PermissionName } from '../../../constants/permissions';
import usePermissions from '../../../hooks/usePermissions';
import StrategyOverviewComponent from '../PredictHub/StrategyMonitor/StrategyOverviewComponent';

const ProjectsMainComponent = lazy(() => import('../ProjectsMainComponent'));

const StrategysRouterComponent = () => {
   const { hasPermission } = usePermissions();

   return (
      <Suspense
         fallback={
            <Skeleton
               className="container py-5 mx-auto h-full"
               paragraph={{ rows: 6 }}
               active
            />
         }
      >
         <div className="container py-5 mx-auto h-full">
            <Routes>
               <Route
                  path="/"
                  element={
                     <PrivateRoute
                        element={<ProjectsMainComponent destination="2" />}
                     />
                  }
               />

               <Route
                  path=":id/monitor"
                  element={
                     <PrivateRoute
                        hasPermission={
                           hasPermission(PermissionName.MONITOR_OVERVIEW) &&
                           hasPermission(PermissionName.ACCESS_PREDICTHUB)
                        }
                        element={<StrategyOverviewComponent />}
                     />
                  }
               />

               <Route
                  path="*"
                  element={
                     <PrivateRoute
                        element={<ProjectsMainComponent destination="2" />}
                     />
                  }
               />
               <Route
                  path=":id"
                  element={
                     <PrivateRoute
                        hasPermission={
                           hasPermission(PermissionName.MONITOR_OVERVIEW) &&
                           hasPermission(PermissionName.ACCESS_PREDICTHUB)
                        }
                        element={<StrategyOverviewComponent />}
                     />
                  }
               />
            </Routes>
         </div>
      </Suspense>
   );
};

export default StrategysRouterComponent;
