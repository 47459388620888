import React, { useEffect, useState } from 'react';
import BreadcrumbComponent from '../../../../components/BreadcrumbComponent';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Tabs } from 'antd';
import LoadingComponent from '../../../../components/LoadingComponent';
import { RootState, useAppDispatch } from '../../../../redux/store';
import { useSelector } from 'react-redux';
import { fetchStrateryById } from '../../../../redux/projects/strategysSlice';
import usePermissions from '../../../../hooks/usePermissions';
import { fetchCompanyById } from '../../../../redux/companies/companiesSlice';
import { PermissionName } from '../../../../constants/permissions';
import UserSvg from '../../../../assets/svg/user';
import PermissionAssignComponent from '../../ProjectSyncswapMonitor/PermissionAssignComponent';
import StrategyMonitorComponent from './StrategyMonitorComponent';
// import CustomSegmentedComponent from '../../../../components/CustomSegmentedComponent';
// import DataStorageSvg from '../../../../assets/svg/dataStorage';
// import BotManageSvg from '../../../../assets/svg/botManage';
// import ManualUpdateSvg from '../../../../assets/svg/botManage copy';
// import ChartGeneratorListComponent from '../../../ChartGenerator2/ChartGeneratorListComponent';

const StrategyOverviewComponent: React.FC = () => {
   const params = useParams();
   // const [overviewCurrency, setOverviewCurrency] =
   //    useState<SelectCurrency>('USD');

   const { stratery, loading: strateryLoading } = useSelector(
      (state: RootState) => state.straterys,
   );

   const { company, loading: companyLoading } = useSelector(
      (state: RootState) => state.companies,
   );

   const dispatch = useAppDispatch();
   const navigate = useNavigate();
   const [isModalOpenAccess, setIsModalOpenAccess] = useState<boolean>(false);
   //    const [activeKeyMMGenerator, setActiveKeyMMGenerator] = useState('1');

   const { hasPermission, isInternalUser } = usePermissions();

   const projectMonitorBreadcrumbs = isInternalUser
      ? [
           {
              title: 'Project Management',
              link: `/company/projects`,
           },
           {
              title: (
                 <LoadingComponent size={20} loading={companyLoading}>
                    {company?.name}
                 </LoadingComponent>
              ),
              link: `/company/${params?.companyId}/strategy/`,
           },
           {
              title: (
                 <LoadingComponent size={20} loading={strateryLoading}>
                    {stratery?.marketGroup?.name}
                 </LoadingComponent>
              ),
           },
        ]
      : [
           {
              title: 'Project Management',
              link: `/company/${params?.companyId}/strategy`,
           },
           {
              title: (
                 <LoadingComponent size={20} loading={strateryLoading}>
                    {stratery?.marketGroup?.name}
                 </LoadingComponent>
              ),
              link: `/company/${params?.companyId}/strategy/${params?.id}/monitor`,
           },
        ];

   useEffect(() => {
      if (!stratery && !strateryLoading && params?.id) {
         dispatch(fetchStrateryById(+params?.id, navigate));
      }
   }, [dispatch, stratery, strateryLoading]);

   useEffect(() => {
      if (params?.companyId) {
         dispatch(fetchCompanyById(+params?.companyId, navigate));
      }
   }, [dispatch, params?.companyId]);

   //    const segmentedItemsMMGenerator = [
   //       {
   //          key: '1',
   //          text: 'Data Storage',
   //          icon: DataStorageSvg,
   //          hasPermission:
   //             hasPermission(PermissionName.ACCESS_PREDICTHUB) &&
   //             hasPermission(PermissionName.MMLIST_VIEW),
   //          component: <ChartGeneratorListComponent />,
   //       },
   //       {
   //          key: '2',
   //          text: 'Bot Manage',
   //          icon: BotManageSvg,
   //          disabled: true,
   //       },
   //       {
   //          key: '3',
   //          text: 'Manual Update',
   //          icon: ManualUpdateSvg,
   //          disabled: true,
   //       },
   //    ];

   return (
      <div>
         <div className="flex justify-between items-center">
            <BreadcrumbComponent items={projectMonitorBreadcrumbs} />
            {hasPermission(PermissionName.PROJECT_ASSIGN) && (
               <>
                  <Button
                     type="primary"
                     icon={<UserSvg />}
                     onClick={() => setIsModalOpenAccess(true)}
                  >
                     Assign
                  </Button>
                  <PermissionAssignComponent
                     id={params?.id}
                     isModalOpen={isModalOpenAccess}
                     setIsModalOpen={setIsModalOpenAccess}
                  />
               </>
            )}
         </div>

         <Tabs defaultActiveKey="1" destroyInactiveTabPane>
            {hasPermission(PermissionName.MONITOR_OVERVIEW) &&
               hasPermission(PermissionName.ACCESS_PREDICTHUB) && (
                  <Tabs.TabPane tab="Monitor" key="1">
                     <StrategyMonitorComponent />
                  </Tabs.TabPane>
               )}
            {/* {hasPermission(PermissionName.ACCESS_PREDICTHUB) &&
               hasPermission(PermissionName.MMLIST_VIEW) && (
                  <Tabs.TabPane tab="MM Generator" key="2">
                     <CustomSegmentedComponent
                        items={segmentedItemsMMGenerator}
                        activeKey={activeKeyMMGenerator}
                        setActiveKey={setActiveKeyMMGenerator}
                        defaultActiveKey="1"
                     />
                  </Tabs.TabPane>
               )} */}
         </Tabs>
      </div>
   );
};

export default StrategyOverviewComponent;
