/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { lazy, useEffect, useRef, useState } from 'react';
import { ConfigProvider, message } from 'antd';
import { User } from '../redux/auth/type';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import HeaderComponent from '../components/HeaderComponent';
import { Content } from 'antd/es/layout/layout';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import PublicRoute from '../routes/PublicRoute';
import LoginComponent from './Login/LoginComponent';
import PrivateRoute from '../routes/PrivateRoute';
import ProjectsRouterComponent from './Projects/router/ProjectsRouterComponent';
import ProjectsSyncswapRouterComponent from './Projects/router/ProjectsSyncswapRouterComponent';
import StrategysRouterComponent from './Projects/router/StrategysRouterComponent';
import ChartGeneratorRouterComponent from './ChartGenerator2/router/ChartGeneratorRouterComponent';
import AccountRouterComponent from './Accounts/router/AccountRouterComponent';
import FooterComponent from '../components/FooterComponent';
import usePermissions from '../hooks/usePermissions';
import CompanyRouterComponent from './Companies/router/CompanyRouterComponent';
import CompanyAccountRouterComponent from './Companies/router/CompanyAccountRouterComponent';
import { PermissionName } from '../constants/permissions';
import LoadingComponent from '../components/LoadingComponent';
import { getProfile } from '../services/authenServices';
import { logout, setUser } from '../redux/auth/authSlice';
import { ERROR_MESSAGE } from '../constants/common';
import FundComponent from './Fund/FundComponent';

message.config({
   // top: 100,
   // duration: 2,
   maxCount: 1,
   // rtl: true,
   // prefixCls: 'my-message',
});

type ThemeData = {
   borderRadius: number;
   colorPrimary: string;
   controlHeight: number;
   boxShadow: string;
   Button?: {
      colorPrimary: string;
      algorithm?: boolean;
   };
   Divider?: {
      fontSize: number;
   };
   Table?: {
      borderColor: string;
      headerBg: string;
   };
};

const antDefaultConfig: ThemeData = {
   borderRadius: 10,
   colorPrimary: '#0CA7EE',
   boxShadow: '0px 2px 6px 0px rgba(59, 59, 66, 0.36)',
   controlHeight: 35,
   Button: {
      colorPrimary: '#0CA7EE',
      algorithm: true,
   },
   Divider: {
      fontSize: 20,
   },
   Table: {
      borderColor: '#E7E7F6',
      headerBg: '#FAFAFC',
   },
};

function LayoutComponent() {
   const { user, isAuthenticated } = useSelector(
      (state: RootState) => state.auth,
   );
   const headerRef = useRef<HTMLDivElement>(null);
   const footerRef = useRef<HTMLDivElement>(null);
   const [layoutHeight, setLayoutHeight] = useState<number>(0);

   const dispatch = useDispatch();
   const [fetchingUser, setFetchingUser] = useState<boolean>(false);
   const { isInternalUser, hasPermission } = usePermissions();
   const companyId = user?.company?.id;

   useEffect(() => {
      const fetchUser = async () => {
         try {
            setFetchingUser(true);
            const userRes = await getProfile();
            dispatch(setUser(userRes?.data?.data as User));
            setFetchingUser(false);
         } catch (error) {
            setFetchingUser(false);
            message.error(ERROR_MESSAGE);
            dispatch(logout());
         }
      };
      if (isAuthenticated) {
         fetchUser();
      }
   }, [isAuthenticated]);

   useEffect(() => {
      if (headerRef.current && footerRef.current) {
         const headerHeight = headerRef.current.offsetHeight;
         const footerHeight = footerRef.current.offsetHeight;
         setLayoutHeight(headerHeight + footerHeight);
      }
   }, [headerRef.current?.offsetHeight, footerRef.current?.offsetHeight]);

   return (
      <ConfigProvider
         theme={{
            token: {
               colorPrimary: antDefaultConfig.colorPrimary,
               borderRadius: antDefaultConfig.borderRadius,
               controlHeight: antDefaultConfig.controlHeight,
            },
            components: {
               Button: {
                  colorPrimary: antDefaultConfig.Button?.colorPrimary,
                  algorithm: antDefaultConfig.Button?.algorithm,
               },
               Divider: {
                  fontSize: antDefaultConfig.Divider?.fontSize,
               },
               Table: {
                  headerBg: antDefaultConfig.Table?.headerBg,
                  borderRadius: 10,
               },
            },
         }}
      >
         <HeaderComponent headerRef={headerRef} />
         <Content
            className="main-content"
            style={{
               // minHeight: `calc(100vh - ${layoutHeight}px)`,
               // background: 'url("/images/background/hero-bg.png")',
               // backgroundRepeat: 'no-repeat',
               // backgroundSize: 'cover',
               background: '#fafafc',
            }}
         >
            <div
               style={{
                  minHeight: `calc(100vh - ${layoutHeight}px)`,
               }}
            >
               <Routes>
                  <Route
                     path="/login"
                     element={<PublicRoute element={<LoginComponent />} />}
                  />
                  <Route
                     path="/company/:companyId/project/*"
                     element={
                        <PrivateRoute element={<ProjectsRouterComponent />} />
                     }
                  />

                  {/* <Route
                              path="/project/*"
                              element={
                                 <PrivateRoute
                                    element={<ProjectsRouterComponent />}
                                 />
                              }
                           /> */}

                  <Route
                     path="/company/:companyId/project-syncswap/*"
                     element={
                        <PrivateRoute
                           element={<ProjectsSyncswapRouterComponent />}
                        />
                     }
                  />

                  <Route
                     path="/company/:companyId/strategy/*"
                     element={
                        <PrivateRoute element={<StrategysRouterComponent />} />
                     }
                  />

                  <Route
                     path="*"
                     element={
                        user ? (
                           <Navigate
                              to={
                                 isInternalUser || !companyId
                                    ? '/company/projects'
                                    : `/company/${companyId}/project`
                              }
                              replace
                           />
                        ) : (
                           <Navigate to="/login" replace />
                        )
                     }
                  />
                  <Route
                     path="/chart-generator/*"
                     element={
                        <PrivateRoute
                           element={<ChartGeneratorRouterComponent />}
                        />
                     }
                  />
                  {hasPermission(PermissionName.COMPANY_VIEW) && (
                     <Route
                        path="/company/projects"
                        element={
                           <PrivateRoute element={<CompanyRouterComponent />} />
                        }
                     />
                  )}
                  {hasPermission(PermissionName.COMPANY_VIEW) && (
                     <Route
                        path="/company/accounts"
                        element={
                           <PrivateRoute
                              element={<CompanyAccountRouterComponent />}
                           />
                        }
                     />
                  )}

                  <Route
                     path="/company/:companyId/account/*"
                     element={
                        <PrivateRoute element={<AccountRouterComponent />} />
                     }
                  />
                  {hasPermission(PermissionName.FUNDMANAGE_VIEW) && (
                     <Route
                        path="/fund"
                        element={<PrivateRoute element={<FundComponent />} />}
                     />
                  )}
               </Routes>
            </div>
            <FooterComponent footerRef={footerRef} />
         </Content>
         <LoadingComponent fullscreen loading={fetchingUser} />
      </ConfigProvider>
   );
}

export default LayoutComponent;
